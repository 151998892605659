import React, { useEffect, useState } from "react";
import "../styles/dashboard.css";
import { Col, Row, Button, Container } from "react-bootstrap";
import { getDataPersonal, getDataForm, cekProsesPendaftaran } from "../api_folder/api";
import * as bs from "react-icons/bs";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

const DashboardOverview = () => {

  const [name, setName] = useState('');
  const [unit, setUnit] = useState('');
  const [imgUrl, setImgUrl] = useState('');

  const [dForm, setDForm] = useState('');
  const [personal, setPersonal] = useState('');
  const [cek, setCek] = useState('');

  const localUsrnm = localStorage.getItem('username');

  useEffect(() => {
    getDataForm(localUsrnm).then((res) => {
      setUnit(res.data.name_unit);
      setDForm(res.data);
    })

    getDataPersonal(localUsrnm).then((res) => {
      if (res.data.message) {
        setName(res.data.message);
      } else {
        setName(res.data.full_name);
        setImgUrl(res.data.image_user);
        setPersonal(res.data);
      }
    })

    cekProsesPendaftaran(localUsrnm).then((res) => {
      setCek(res.data);
    })
  }, [localUsrnm])

  const progres = [
    {
      text: "Register formulir",
      form: dForm.status_register !== 0,
    },
    {
      text: "Melengkapi data pribadi",
      form: cek.data_pribadi !== 0,
    },
    {
      text: "Melengkapi data periodik",
      form: cek.data_periodik !== 0,
    },
    {
      text: "Mengunggah Kartu Keluarga (KK)",
      form: Boolean(personal.file_kk),
    },
    {
      text: "Melengkapi data orang tua / wali",
      form: cek.data_ortu !== 0,
    },
    // {
    //   text: "Download dokumen untuk keperluan tes",
    //   form: dForm.status_pass === 0,
    // },
    // {
    //   text: "Melakukan tes dan cek kelulusan",
    //   form: dForm.status_pass === 0,
    // },
    // {
    //   text: "Melakukan pembayaran",
    //   form: dForm.status_pass === 0,
    // },
  ];

  return (
    <div className="p-5 overflow-hidden min-vh-100">
      <Container fluid className="position-relative">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="d-flex point gap-3 mb-2">
          <h2 className="fw-medium">Overview</h2>
        </div>
        <Row className="mt-3 gap-3 justify-content-sm-center justify-content-lg-start">
          <Col sm={10} xl={7}>
            <div className="p-2 rounded" style={{ background: "#EDFCF2" }}>
              <h5 className="mb-2">Progres Pendaftaran</h5>
              <ul className="list-unstyled">
                {progres?.map((e, i) => (
                  <li key={i} className="d-flex align-items-center gap-2">
                    {e.form === false ? (
                      <IconContext.Provider value={{ color: '#00923f' }}>
                        <bs.BsCircle />
                      </IconContext.Provider>
                    ) : (
                      <IconContext.Provider value={{ color: '#00923f' }}>
                        <bs.BsCheckCircleFill />
                      </IconContext.Provider>
                    )}
                    <p style={{ color: "#697586" }}>{e.text}</p>
                  </li>
                ))}
              </ul>
            </div>
            <Link to="/dashboard/profile">
              <Button
                variant="success"
                className=" d-flex justify-content-between p-2 mt-3"
              >
                Lengkapi data pribadi sekarang
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-chevron-right"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M9 6l6 6l-6 6"></path>
                </svg>
              </Button>
            </Link>
          </Col>
          <Col sm={7} xl={4} className="mt-xs-5 mt-0 ">
            <div className="profile-head-overview mx-auto ">
              <div className="profile-overview">
                <div className="circle-profile">
                  {imgUrl !== '' ? (
                    <img className="w-100 rounded-circle poto" style={{ objectFit: 'cover' }} src={imgUrl + '?version=' + Math.floor((Math.random() * 1000 ) + 1)} alt="img" />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-user"
                      width="88"
                      height="43"
                      viewBox="0 0 24 24"
                      strokeWidth="2"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                    </svg>
                  )}
                </div>
              </div>
              <div
                className="mt-4"
                style={{ color: "#697586", fontSize: "15px" }}
              >
                <div className="d-flex gap-2 mt-3">
                  Nama <span>{name}</span>
                </div>
                <div className="d-flex gap-2 mt-1">
                  Unit <span>{unit}</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardOverview;
