import "../styles/RegisterFlow.css";
import Button from "react-bootstrap/Button";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";

function InternalRegister() {

    return (
        <>
            <div className="container page3">
                <div className="content">
                    <div className="text-center imgResp">
                        {/* kalau pakai Link malah error */}
                        <a href="/images/image4.png">
                            <img src="/images/image4.png" alt="Gambar Alur Eksternal" className="img-fluid" />
                        </a>
                    </div>
                    <div className="mt-3">
                        <Link to='/register-internal'>
                            <Button variant="login" className="rounded-pill tryCenterIcon">
                                <AiIcons.AiOutlineFile /> Daftar Internal
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
export default InternalRegister;