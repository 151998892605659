import { useEffect, useState } from "react";
import { Col, Row, Container, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import { getDataPeriodik } from "../api_folder/api";
import { sendDataPeriodik } from "../api_folder/api_sendData";

const FormDataPeriodik = () => {
  const localUsrnm = localStorage.getItem("username");
  const regex = /(<([^>]+)>)/gi;

  const [message, setMessage] = useState('');

  const [checked, setChecked] = useState(false);

  const [tinggi, setTinggi] = useState('');
  const [berat, setBerat] = useState('');
  const [pala, setPala] = useState('');
  const [darah, setDarah] = useState('');
  const [riwayat, setRiwayat] = useState('');
  const [penyakit, setPenyakit] = useState('');
  const [jarak, setJarak] = useState('');
  const [waktu, setWaktu] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    sendDataPeriodik(localUsrnm, tinggi, berat, pala, darah, riwayat, penyakit, jarak, waktu).then((res) => {
      setMessage(res.data.message)
    })
  };

  useEffect(() => {
    getDataPeriodik(localUsrnm).then((res) => {
      setTinggi(res.data.height)
      setBerat(res.data.weight)
      setPala(res.data.head_size)
      setDarah(res.data.blood_type)
      setRiwayat(res.data.disease_history)
      setPenyakit(res.data.disease)
      setJarak(res.data.distance_house)
      setWaktu(res.data.traveling_time)
    })
  }, [localUsrnm])

  useEffect(() => {
    setMessage('');
  }, [tinggi, berat, pala, darah, riwayat, penyakit, jarak, waktu])
  return (
    <section className="p-5 overflow-hidden">
      <Container className="position-relative ">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="d-flex point gap-3 mb-2">
          <h2 className="fw-medium fs-2 ">Edit Data</h2>
        </div>

        <Link to={"/dashboard/profile"} style={{ color: "#00923F" }}>
          <BsArrowLeft /> Kembali ke profile utama
        </Link>
        <Form onSubmit={handleSubmit}>
          <h5 className="fw-medium text-dark mt-3">Data Periodik</h5>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="tb">
                <Form.Label className="text-neutral">
                  Tinggi badan &#40;dalam cm&#41;
                </Form.Label>
                <Form.Control
                  type="text"
                  name="tb"
                  onChange={e => setTinggi(e.target.value)}
                  className="input-comp"
                  placeholder="Masukkan tinggi badan dalam cm"
                  defaultValue={tinggi}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="bb">
                <Form.Label className="text-neutral">
                  Berat badan &#40;dalam kg&#41;
                </Form.Label>
                <Form.Control
                  type="text"
                  name="bb"
                  onChange={e => setBerat(e.target.value)}
                  className="input-comp"
                  placeholder="Masukkan berat badan dalam kg"
                  defaultValue={berat}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="lingkarKepala">
                <Form.Label className="text-neutral">
                  Lingkar kepala &#40;dalam cm&#41;
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lingkarKepala"
                  onChange={e => setPala(e.target.value)}
                  className="input-comp"
                  placeholder="Masukkan lingkar kepala dalam cm"
                  defaultValue={pala}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="golonganDarah">
                <Form.Label className="text-neutral">Golongan darah</Form.Label>
                <Form.Control
                  type="text"
                  name="golonganDarah"
                  onChange={e => setDarah(e.target.value)}
                  className="input-comp"
                  placeholder="Masukkan golongan darah"
                  defaultValue={darah}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Riwayat penyakit
                </Form.Label>
                <Form.Select
                  aria-label="Riwayat Penyakit"
                  className="input-comp"
                  name="riwayatPenyakit"
                  onChange={e => setRiwayat(e.target.value)}
                  defaultValue={riwayat}
                  required
                >
                  <option value="">Pilih Ada/Tidak ada</option>
                  <option value="Ada">Ada</option>
                  <option value="Tidak Ada">Tidak Ada</option>
                </Form.Select>
              </Form.Group>
              {riwayat === 'Ada' ? (
                <Form.Group className="mb-3" controlId="golonganDarah">
                  <Form.Label className="text-neutral">Penyakit</Form.Label>
                  <Form.Control
                    type="text"
                    name="golonganDarah"
                    onChange={e => setPenyakit(e.target.value)}
                    className="input-comp"
                    placeholder="Masukkan penyakit"
                    defaultValue={penyakit}
                    required
                  />
                </Form.Group>
              ) : (
                <span></span>
              )}

            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="jarakSekolah">
                <Form.Label className="text-neutral">
                  Jarak rumah ke sekolah &#40;dalam km&#41;
                </Form.Label>
                <Form.Control
                  type="text"
                  name="jarakSekolah"
                  onChange={e => setJarak(e.target.value)}
                  className="input-comp"
                  placeholder="Masukkan jarak rumah ke sekolah dengan km"
                  defaultValue={jarak}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="waktuTempuh">
                <Form.Label className="text-neutral">
                  Waktu tempuh ke sekolah
                </Form.Label>
                <Form.Control
                  type="text"
                  name="waktuTempuh"
                  onChange={e => setWaktu(e.target.value)}
                  className="input-comp"
                  placeholder="Masukkan waktu tempuh ke sekolah"
                  defaultValue={waktu}
                  required
                />
              </Form.Group>
              <hr />
              <Form.Check
                type="checkbox"
                name="cek"
                id="checkbox"
                onChange={e => setChecked(e.target.checked)}
                label="Dengan ini saya menyatakan bahwa data yang saya isi adalah benar adanya. Jika dikemudian hari terbukti bahwa data tersebut tidak benar, maka saya bersedia didisklualifikasi dari proses PPDB Asy-Syukriyyah."
                style={{ color: "#697586" }}
                required
              />
              <hr />
              <Row className="mt-2 gap-3 gap-md-0">
                {message && (
                  <span>
                    {message !== 'input form berhasil' ? (
                      <Alert variant="danger">
                        {message.replace(regex, " ")}
                      </Alert>
                    ) : (
                      <Alert variant="success">
                        {message.replace(regex, " ")}
                      </Alert>
                    )}
                  </span>
                )}
                <Col sm={6}>
                  <Button
                    disabled={!checked}
                    variant="form-edit"
                    type="submit"
                    className="simpan-next"
                  >
                    Simpan
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button
                    variant="form-edit"
                    className="selesai"
                  >
                    Selesai
                  </Button>
                </Col>
                <Col className="mt-lg-4">
                  <Link to='/dashboard/edit-kk'>
                    <Button
                      variant="form-edit"
                      className="simpan-next"
                    >
                      Selanjutnya
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  );
};

export default FormDataPeriodik;
