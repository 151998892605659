import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "bootstrap/js/dist/collapse";
import "./styles/custom.scss";
import App from "./App";
import Register from "./pages/RegisterMainPage";
import RegInternal from "./pages/RegisterInternal";
import RegInternal2 from "./pages/RegisterInternalForm";
import RegReguler from "./pages/RegisterReguler";
import RegPindahan from "./pages/RegisterPindahan";
import RegSuccess from "./pages/RegisterSuccess";
import RegIntSuccess from "./pages/RegisterInternalSuccess";
import UnitTKIT from "./pages/UnitTKIT";
import UnitSDIT from "./pages/UnitSDIT";
import UnitSDIT1 from "./pages/UnitSDIT1";
import UnitMIPlus from "./pages/UnitMIPlus";
import UnitSMPIT from "./pages/UnitSMPIT";
import UnitMTS from "./pages/UnitMTS";
import UnitSMAIT from "./pages/UnitSMAIT";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import DashboardOverview from "./components/DashboardOverview";
import DashboardDownload from "./components/DashboardDownload";
import DashboardProfile from "./components/DashboardProfile";
import DashboardPayment from "./components/DashboardPayment";
import FormProfile from "./pages/FormProfile";
import FormKk from "./pages/FormKk";
import FormOrtu from "./pages/FormOrtu";
import FormDataPeriodik from "./pages/FormDataPeriodik";
import { AuthProvider } from "./api_folder/AuthProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <App />
        <Outlet />
      </>
    ),
    errorElement: <h1>Error: page not found</h1>,
  },
  {
    path: "/register",
    element: (
      <>
        <Register />
        <Outlet />
      </>
    ),
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register-internal",
    element: <RegInternal />,
  },
  {
    path: "/register-internal2",
    element: <RegInternal2 />,
  },
  {
    path: "/register-reguler",
    element: <RegReguler />,
  },
  {
    path: "/register-pindahan",
    element: <RegPindahan />,
  },
  {
    path: "/register-success",
    element: <RegSuccess />,
  },
  {
    path: "/register-internal-success",
    element: <RegIntSuccess />,
  },
  {
    path: "/unit-tkit",
    element: <UnitTKIT />,
  },
  {
    path: "/unit-sdit",
    element: <UnitSDIT />,
  },
  {
    path: "/unit-sdit1",
    element: <UnitSDIT1 />,
  },
  {
    path: "/unit-miplus",
    element: <UnitMIPlus />,
  },
  {
    path: "/unit-smpit",
    element: <UnitSMPIT />,
  },
  {
    path: "/unit-mts",
    element: <UnitMTS />,
  },
  {
    path: "/unit-smait",
    element: <UnitSMAIT />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    children: [
      {
        index: true,
        element: <DashboardOverview />,
      },
      {
        path: "profile",
        element: <DashboardProfile />,
      },
      {
        path: "pembayaran",
        element: <DashboardPayment />,
      },
      {
        path: "download",
        element: <DashboardDownload />,
      },
      {
        path: "edit-profile",
        element: <FormProfile />,
      },
      {
        path: "edit-dataPeriodik",
        element: <FormDataPeriodik />,
      },
      {
        path: "edit-orangTua",
        element: <FormOrtu />,
      },
      {
        path: "edit-kk",
        element: <FormKk />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);
