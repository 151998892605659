import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import "../styles/dashboard.css";
import { Link } from "react-router-dom";
import { getDataPersonal } from "../api_folder/api";

const Profile = () => {

  const localUsrnm = localStorage.getItem('username');
  const [data, setData] = useState([]);

  useEffect(() => {
    getDataPersonal(localUsrnm).then((res) => {
      setData(res.data);
    })
  }, [localUsrnm])

  const dataPribadi = [
    {
      title: "Nama Lengkap",
      text: data.full_name,
    },
    {
      title: "Nama Panggilan",
      text: data.nick_name,
    },
    {
      title: "Anak keberapa",
      text: data.child_no,
    },
    {
      title: "Jumlah Saudara Kandung",
      text: data.sibling === "0" ? (" Anak Tunggal") : data.sibling,
    },
    {
      title: "Saudara Kandung Sedang Bersekolah di Asy-Syukriyyah",
      text: data.sibling_school_yesno === '0' ? ("Tidak ada") : data.sibling_school,
    },
    {
      title: "NIK",
      text: data.nik,
    },
    {
      title: "No.KK",
      text: data.nokk,
    },
    {
      title: "NISN",
      text: data.nisn,
    },
    {
      title: "Berkebutuhan Khusus",
      text: data.disability,
    },
    {
      title: "Alamat Rumah",
      text: data.address,
    },
    {
      title: "Provinsi",
      text: data.name_province,
    },
    {
      title: "Kota / Kabupaten",
      text: data.name_city,
    },
    {
      title: "Kecamatan",
      text: data.name_district,
    },
    {
      title: "Desa / Kelurahan",
      text: data.name_village,
    },
    {
      title: "RT/RW",
      text: data.rt_rw,
    },
    {
      title: "Kode Pos",
      text: data.postal_code,
    },
    {
      title: "Status Tempat Tinggal",
      text: data.living_status,
    },
    {
      title: "Transportasi",
      text: data.transportation,
    },
    {
      title: "Kewarganegaraan",
      text: data.nationality,
    },
  ];
  return (
    <section className="data-profile">
      <div className="d-flex flex-column">
        <div className="position-relative">
          <div className="circle-profile">
            {data.image_user ? (
              <img className="w-100 rounded-circle poto" style={{ objectFit: 'cover' }} src={data.image_user + '?version=' + Math.floor((Math.random() * 1000) + 1)} alt="img" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-user"
                width="100"
                height="46"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
              </svg>
            )}
          </div>
          <Link
            to={"/dashboard/edit-profile"}
            variant="light"
            className="btn-edit-dataDiri position-absolute top-0 end-0"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-edit fw-bold"
              width="100"
              height="100"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
              <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
              <path d="M16 5l3 3"></path>
            </svg>
            <span className="fw-500">Edit</span>
          </Link>
        </div>
        <div className="mt-2">
          <Table className="tbl">
            <tbody>
              {dataPribadi?.map((e, i) => (
                <tr key={i}>
                  <td width={170}>{e.title}</td>
                  <td width={250}>
                    {e.text ? (
                      <span>{e.text}</span>
                    ) : (
                      <button className="status-text">Belum Terisi</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default Profile;
