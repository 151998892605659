import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../styles/Register.css";
import * as BsIcons from "react-icons/bs";
import { ceknis } from "../api_folder/api";
import { useEffect, useState } from "react";
import Alert from 'react-bootstrap/Alert';
import { useNavigate } from "react-router";

function RegisterInternal() {
  let studentData = [];
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState('');
  const [nik, setNik] = useState([]),
    onInput = ({ target: { value } }) => setNik(value),
    onFormSubmit = e => {
      e.preventDefault();
      ceknis(nik).then((res) => {
        if (res.data.status === 'fail') setErrMessage('NIS tidak ditemukan. Silahkan periksa kembali NIS anda');
        if (res.data.status === 'success') {
          studentData = res.data;
          sessionStorage.setItem('nis', studentData.nis);
          setNik('');
          navigate('/register-internal2')
        }
      })
    };

  useEffect(() => {
    setErrMessage('');
  }, [nik])

  return (
    <>
      <section className="bg-light">
        <Navbar expand="sm" variant="custom" bsPrefix={""} fixed="top">
          <Navbar.Brand>
            <img
              src="/images/logo.svg"
              width="44"
              height="45"
              className="d-inline-block align-top ms-3"
              alt="logo"
            />
          </Navbar.Brand>
          <Nav className="ms-auto">
            <Nav.Link className="me-3">
              PPDB Asy-Syukriyyah Islamic School
            </Nav.Link>
          </Nav>
        </Navbar>

        <div className="position-relative">
          <div className="forText">
            <a href="/" className="back">
              <BsIcons.BsArrowLeftShort />
              Kembali ke halaman utama
            </a>
          </div>
        </div>
        <Container className="v-screen d-flex align-items-center justify-content-center">
          <Row className="justify-content-center">
            <Col>
              {errMessage && (
                <Alert variant="danger">
                  {errMessage}
                </Alert>
              )}
              <div className="newBox">
                <h3>Pendaftaran Internal</h3>
                <p style={{ color: "#697586" }}>
                  Pendaftar Internal tidak perlu melakukan pembayaran formulir
                  (free biaya formulir), mohon abaikan tagihan IDN. Nomor Induk
                  Siswa (NIS) dapat ditanyakan kepada TU atau Walas
                  masing-masing unit pendidikan.
                </p>
                <Form className="formAsdf" onSubmit={onFormSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      <b>NIS (Nomor Induk Siswa)</b>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Masukan Nomor Induk Siswa"
                      onChange={onInput}
                      value={nik}
                      required
                    />
                    <Form.Text className="text-muted">
                      contoh: 12340118
                    </Form.Text>
                  </Form.Group>
                  <Button variant="cekdata" className="rounded-pill" type="submit">
                    Cek Data
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <span className="circlex" />
        <span className="circley" />
      </section>
    </>
  );
}
export default RegisterInternal;
