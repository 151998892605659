import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Button, Alert } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { sendDataKK } from "../api_folder/api_sendData";

const TestKk = () => {
  const [preview, setPreview] = useState(null);
  const [nameImg, setNameImg] = useState(null);
  const [loading, setLoading] = useState(false);
  // const [next, setNext] = useState(false);
  const [check, setCheck] = useState(false);
  const [fileBs64, setFileBs64] = useState('');

  const [errMessage, setErrMessage] = useState('');
  const [message, setMessage] = useState('');

  const localUsrnm = localStorage.getItem('username');

  const MAX_FILE_SIZE = 1024;

  let selectedFile = null;

  const handleImageUpload = (e) => {
    // setNext(true);
    e.preventDefault()
    if (fileBs64.length === 0) {
      setErrMessage('File tidak bisa kosong!')
    } else if (fileBs64.length !== 0) {
      sendDataKK(localUsrnm, fileBs64).then((res) => {
        setMessage(res.data.message)
      })
    }

  };

  const handleImageSelect = (event) => {

    const file = event.target.files[0];
    if (file !== null) {
      setNameImg(file.name);
    } else if (file === null) {
      setNameImg('belum dipilih')
    }

    if (file) {
      setLoading(true);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setLoading(false);
      };
      reader.readAsDataURL(file);
    }

    selectedFile = event.target.files;

    if (selectedFile[0].size / 1024 > MAX_FILE_SIZE) {
      setErrMessage('Ukuran file terlalu besar')
      return false;
    } else {
      setErrMessage('')
      let filez = null;
      let fileName = "";
      //Check File is not Empty
      if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        // eslint-disable-next-line
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          filez = fileLoadedEvent.target.result;
          // Print data in console
          // console.log(filez);
          setFileBs64(filez)
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
    }
  };

  useEffect(() => {
    setErrMessage('');
  }, [selectedFile])

  return (
    <section className="p-5 overflow-hidden">
      <Container className="position-relative">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="d-flex point gap-3 mb-2">
          <h2 className="fw-medium fs-2 ">Edit Data</h2>
        </div>

        <Link to={"/dashboard/profile"} style={{ color: "#00923F" }}>
          <BsArrowLeft /> Kembali ke profile utama
        </Link>
        <Form onSubmit={handleImageUpload}>
          <h5 className="fw-medium text-dark mt-3">
            Kartu Keluarga &#40;KK&#41;
          </h5>
          <Row className="mt-4 justify-content-center align-items-center">
            <Col lg={7}>
              <Form.Group
                className="mb-4 gap-3 d-flex flex-column align-items-center"
                controlId="formFile"
              >
                <h6 className="text-neutral">Unggah Dokumen</h6>
                {preview ? (
                  <img
                    src={preview}
                    className="poto"
                    style={{
                      backgroundPosition: "center",
                      objectFit: "cover",
                    }}
                    alt="poto"
                  />
                ) : (
                  <Form.Label className="poto">
                    <svg
                      width="90"
                      height="90"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="file">
                        <path
                          id="Icon"
                          d="M37.916 5.83398H17.4993C15.9523 5.83398 14.4685 6.44857 13.3746 7.54253C12.2806 8.63649 11.666 10.1202 11.666 11.6673V58.334C11.666 59.8811 12.2806 61.3648 13.3746 62.4588C14.4685 63.5527 15.9523 64.1673 17.4993 64.1673H52.4994C54.0464 64.1673 55.5302 63.5527 56.6241 62.4588C57.7181 61.3648 58.3327 59.8811 58.3327 58.334V26.2506M37.916 5.83398L58.3327 26.2506M37.916 5.83398V26.2506H58.3327"
                          stroke="#697586"
                          strokeWidth="8.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </Form.Label>
                )}
                <Form.Text style={{ marginTop: "-14px" }}>
                  Max File: 1 MB
                </Form.Text>
                {loading && (
                  <div className="loading-bar">
                    <div className="progress" />
                  </div>
                )}
                {nameImg}
                {errMessage && (
                  <Alert variant="danger">Error: {errMessage}</Alert>
                )}
                {message && (
                  <Alert variant="info">Berhasil: {message}</Alert>
                )}

                <input
                  type="file"
                  accept="image/*, .pdf"
                  style={{ width: "90px" }}
                  className="custom-file-input"
                  onChange={handleImageSelect}
                />
              </Form.Group>
              <Form.Check
                type="checkbox"
                id="checkbox"
                onChange={(e) => setCheck(e.target.checked)}
                label="Dengan ini saya menyatakan bahwa data yang saya isi adalah benar adanya. Jika dikemudian hari terbukti bahwa data tersebut tidak benar, maka saya bersedia didisklualifikasi dari proses PPDB Asy-Syukriyyah."
                style={{ color: "#697586" }}
              />

              <Form.Group className="mt-3">
                <Button
                  disabled={!check}
                  variant="save-edit"
                  style={{ color: "#00923F", background: "#FCFCFD" }}
                  type="submit"
                >
                  Simpan
                </Button>
                <hr />
                <Link to='/dashboard/edit-orangTua'>
                  <Button
                    variant="save-edit"
                    style={{ background: "#FCFCFD", color: "#00923F" }}
                  >
                    Selanjutnya
                  </Button>
                </Link>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  );
};

export default TestKk;
