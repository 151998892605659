import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useRef, useState } from "react";
import "./styles/App.css";
import FirstPage from "./components/Home";
import SecondPage from "./components/OurProgram";
import ThirdPage from "./components/RegisterFlow";
import FourthPage from "./components/InternalRegister";
import FifthPage from "./components/Contact";
import { Col, Row } from "react-bootstrap";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io5";
import { IconContext } from "react-icons/";
import { Link } from "react-router-dom";

function App() {
  const home = useRef(null);
  const program = useRef(null);
  const alur = useRef(null);
  const interRegister = useRef(null);
  const kontak = useRef(null);

  const fullpageRef = useRef(null);

  const [showZxc, setShowZxc] = useState(false);

  const executeScroll = (daRef) => {
    daRef.current.scrollIntoView({ behavior: "smooth"});
  };
  const scrollToNextPage = () => {
    const pages = fullpageRef.current.children;
    const pageHeight = fullpageRef.current.clientHeight;
    const currentPageIndex = Math.floor(
      fullpageRef.current.scrollTop / pageHeight
    );
    if (currentPageIndex < pages.length - 1) {
      fullpageRef.current.scrollTo({
        top: (currentPageIndex + 1) * pageHeight,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    fullpageRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = fullpageRef.current;
    const isAtBottom = scrollTop + clientHeight === scrollHeight;

    if (isAtBottom) {
      setShowZxc(true);
    } else {
      setShowZxc(false);
    }
  };

  const SectionStyle = {
    minHeight: "100vh",
    width: "100%",
    display: "flex",
    scrollSnapAlign: "start",
  };
  const FooterStyle = {
    backgroundColor: "#00923F",
    scrollSnapAlign: "start",
  };
  return (
    <div className="appBody">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="custom"
        bsPrefix={""}
        fixed="top"
      >
        <Container>
          <Navbar.Brand href="#home">
            <img
              src="/images/logo.svg"
              width="44"
              height="45"
              className="d-inline-block align-top"
              alt="logo"
              onClick={() => executeScroll(home)}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link
                className="me-3"
                href="#home"
                onClick={() => executeScroll(home)}
              >
                Beranda
              </Nav.Link>
              <Nav.Link
                className="me-3"
                href="#program"
                onClick={() => executeScroll(program)}
              >
                Program kami
              </Nav.Link>
              <Nav.Link
                className="me-3"
                href="#alur"
                onClick={() => executeScroll(alur)}
              >
                Alur pendaftaran
              </Nav.Link>
              <Nav.Link
                className="me-3"
                href="#kontak"
                onClick={() => executeScroll(kontak)}
              >
                Kontak
              </Nav.Link>
              <div>
                <Link to="/login">
                  <Button
                    size="sm"
                    variant="login"
                    className="rounded-pill mt-1"
                  >
                    Login Dashboard
                  </Button>
                </Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <>
        <div className="kontainer" ref={fullpageRef} onScroll={handleScroll}>
          <section
            ref={home}
            style={{
              ...SectionStyle,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FirstPage />
          </section>
          <section
            ref={program}
            style={{
              ...SectionStyle,
              background:
                "linear-gradient(188.63deg, #CCE9D9 -5.14%, #F8FAFC 37.79%)",
            }}
          >
            <SecondPage />
          </section>
          <section
            ref={alur}
            style={{ ...SectionStyle, backgroundColor: "white" }}
          >
            <ThirdPage />
          </section>
          <section
            ref={interRegister}
            style={{ ...SectionStyle, backgroundColor: "white" }}
          >
            <FourthPage />
          </section>
          <section
            ref={kontak}
            style={{
              ...SectionStyle,
              background:
                "linear-gradient(13.29deg, #009209 -13.27%, #FFFFFF 25.61%)",
            }}
          >
            <FifthPage />
          </section>
          <section style={FooterStyle}>
          <footer className="bd-footer py-4 py-md-5 bg-body-tertiary">
        <div className="container py-4 py-md-5 px-4 px-md-3 text-body-secondary whiteColor">
          <Row>
            <Col lg={5} xs={12} className="mb-3">
              <a
                className="d-inline-flex align-items-center mb-2 text-body-emphasis text-decoration-none"
                href="/"
                aria-label="Bootstrap"
              >
                <img
                  src="/images/logo.svg"
                  width="100"
                  height="100"
                  className="d-inline-block align-top"
                  alt="logo"
                />
              </a>
              <ul className="list-unstyled small">
                <li>
                  Asy-Syukriyyah Islamic School adalah sekolah Islam unggulan yang terletak di Kota Tangerang. Memiliki visi untuk menjadi pusat pendidikan Islam yang unggul, modern, dan berwawasan global.
                </li>
                <li style={{ marginTop: "20px" }}>
                  <HiIcons.HiOutlineLocationMarker /> Jalan KH. Hasyim Ashari
                  No.41 Poris Plawad Indah, Cipondoh, Kota Tangerang, Banten
                  15141{" "}
                </li>
                <li>
                  <HiIcons.HiOutlineLocationMarker /> Jalan Irigasi RT. 06 RW.
                  06, Kel Buaran Indah Kecamatan Tangerang, Kota Tangerang
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={3} className="mb-2">
              <h5>Asy-Syukriyyah Islamic School</h5>
              <ul className="list-unstyled">
                <Link to={'#home'} onClick={() => executeScroll(home)} style={{ textDecoration: 'none', color: 'white' }}><p>Beranda</p></Link><br />
                <Link to={'#program'} onClick={() => executeScroll(program)} style={{ textDecoration: 'none', color: 'white' }}><p>Program kami</p></Link><br />
                <Link to={'#alur'} onClick={() => executeScroll(alur)} style={{ textDecoration: 'none', color: 'white' }}><p>Alur pendaftaran</p></Link><br />
                <Link to={'#kontak'} onClick={() => executeScroll(kontak)} style={{ textDecoration: 'none', color: 'white' }}><p>Kontak</p></Link><br />
              </ul>
            </Col>
            <Col xs={12} lg={2} className="mb-2">
              <h5>Program kami</h5>
              <ul className="list-unstyled">
                <Link to={'/unit-tkit'} style={{ textDecoration: 'none', color: 'white' }}><p>TKIT Asy-Syukriyyah</p></Link><br />
                <Link to={'/unit-sdit'} style={{ textDecoration: 'none', color: 'white' }}><p>SDIT Asy-Syukriyyah</p></Link><br />
                <Link to={'/unit-sdit1'} style={{ textDecoration: 'none', color: 'white' }}><p>SDIT Quran Asy-Syukriyyah Faturrahman</p></Link><br />
                <Link to={'/unit-smpit'} style={{ textDecoration: 'none', color: 'white' }}><p>SMPIT Asy-Syukriyyah</p></Link><br />
                <Link to={'/unit-miplus'} style={{ textDecoration: 'none', color: 'white' }}><p>MIPlus Asy-Syukriyyah</p></Link><br />
                <Link to={'/unit-mts'} style={{ textDecoration: 'none', color: 'white' }}><p>MTsPlus Asy-Syukriyyah</p></Link><br />
                <Link to={'/unit-smait'} style={{ textDecoration: 'none', color: 'white' }}><p>SMAIT Asy-Syukriyyah</p></Link>
              </ul>
            </Col>
            <Col lg={2} className="mb-2">
              <h5>Social media</h5>
              <div style={{ display: "flex" }}>
                <Link to={'https://www.instagram.com/asysyukriyyah.tng/'} target="_blank"><BsIcons.BsInstagram size={40} color="white" /></Link>
                <Link to={'https://www.facebook.com/YayasanIslamAsySyukriyyah/?ref=embed_page'} target="_blank"><BsIcons.BsFacebook size={40} color="white" className="ms-4" /></Link>
                <Link to={'https://www.youtube.com/@asy-syukriyyahchannel5852'} target="_blank"><IoIcons.IoLogoYoutube size={40} color="white" className="ms-4"/></Link>
              </div>
            </Col>
          </Row>
        </div>
      </footer>
          </section>
          {showZxc ? (
            <Button
              id="scrollButton"
              className="scroll-button"
              onClick={scrollToTop}
              variant="custom"
            >
              <IconContext.Provider value={{ color: "green" }}>
                <AiIcons.AiOutlineArrowUp />
              </IconContext.Provider>
            </Button>
          ) : (
            <Button
              id="scrollButton"
              className="scroll-button"
              onClick={scrollToNextPage}
              variant="custom"
            >
              <IconContext.Provider value={{ color: "green" }}>
                <AiIcons.AiOutlineArrowDown />
              </IconContext.Provider>
            </Button>
          )}
        </div>
      </>
    </div>
  );
}

export default App;
