import Petunjuk from "./components/Petunjuk";
import Biaya from "./components/Biaya";
import DaftarUlang from "./components/DaftarUlang";
import DownloadFormulir from "./components/DownloadFormulir";
import DownloadKartuPeserta from "./components/DownloadKartuPeserta";
import DownloadMou from "./components/DownloadMou";

export const CallSchool = [
  {
    imgUrl: "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-tkit.png",
    title: "TKIT",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6282139045024",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-sdit-asy-syukriyyah.png",
    title: "SDIT",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281212330892",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-sdit-asyfa-quranic-school.jpeg",
    title: "SDIT Qur'an Faturrahman",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281319756811",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-smpit-asy-syukriyyah.png",
    title: "SMPIT",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281212330893",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-smpit-asy-syukriyyah.png",
    title: "SMPIT Qur'an",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281319756811",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-miplus-asy-syukriyyah.png",
    title: "MI Plus",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281399247740",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-mts-plus-asy-syukriyyah.png",
    title: "MTS Plus",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281381610181",
    btnCaption: "WhatsApp",
  },
  {
    imgUrl:
      "https://ppdb.asy-syukriyyah.sch.id/asset/images/logo-smait-asy-syukriyyah.png",
    title: "SMAIT",
    email: "email@email.com",
    whatsapp: "https://wa.me/+6281212330894",
    btnCaption: "WhatsApp",
  },
];

export const SchoolProgram = [
  {
    imgUrl: "/images/TUMBNAIL_TKIT.jpg",
    title: "TKIT",
    text: "Pendaftaran dibuka pada",
    options: ["Playground", "TK A", "TK B"],
    registrasi: true,
    date: "16 Oktober 2023",
    detail: "/unit-tkit",
  },
  {
    imgUrl:
      "/images/TUMBNAIL_SDIT.jpg",
    title: "SDIT",
    text: "Pendaftaran telah ditutup",
    options: ["Regular", "Qur'an Asyfa"],
    registrasi: false,
    date: null,
    detail: "/unit-sdit",
  },
  {
    imgUrl:
      "/images/TUMBNAIL_MIPLUS.jpg",
    title: "MI Plus",
    text: "Penutupan pendaftaran",
    options: ["Regular"],
    registrasi: true,
    date: "14 Hari lagi",
    detail: "/unit-miplus",
  },
  {
    imgUrl:
      "/images/TUMBNAIL_SMPIT.jpg",
    title: "SMPIT",
    text: "Pendaftaran dibuka",
    options: ["Regular", "Qur’an Faturrahman"],
    registrasi: true,
    date: "14 Hari lagi",
    detail: "/unit-smpit",
  },
  {
    imgUrl:
      "/images/TUMBNAIL_MTSPLUS.jpg",
    title: "MTS Plus",
    text: "Pendaftaran dibuka",
    options: ["Regular"],
    registrasi: true,
    date: "14 Hari lagi",
    detail: "/unit-mts",
  },
  {
    imgUrl:
      "/images/TUMBNAIL_SMAIT.jpg",
    title: "SMAIT",
    text: "Pendaftaran dibuka",
    options: ["Regular"],
    registrasi: true,
    date: "14 Hari lagi",
    detail: "/unit-smait",
  },
];
export const dataRegister = [
  {
    title: "No. Pendaftaran",
    text: 10,
  },
  {
    title: "Unit",
    text: "SMPIT Qur’an Asy-Syukriyyah",
  },
  {
    title: "Nama",
    text: "Anastasya Felani Anggraini Setiaputri",
  },
  {
    title: "Asal Sekoalah",
    text: "Anastasya Felani Anggraini Setiaputri",
  },
  { title: "Jenis Kelamin", text: "Perempuan" },
  { title: "Tempat Lahir", text: "Tanggerang" },
  { title: "Tanggal Lahir", text: "12-12-12" },
  { title: "No. Telepon", text: "089231237" },
  { title: "No. WhatsApp", text: "089123" },
  { title: "Email", text: "email@gmail.com" },
];

export const dataPribadi = [
  {
    title: "Nama Lengkap",
    text: "Anastasya Felani Anggraini Setiaputri",
  },
  {
    title: "Nama Panggilan",
    text: false,
  },
  {
    title: "Asal Sekolah",
    text: "Sekolah",
  },
  {
    title: "Anak keberapa",
    text: false,
  },
  {
    title: "Jumlah Saudara Kandung",
    text: false,
  },
  {
    title: "Saudara Kandung Sedang Bersekolah di Asy-Syukriyyah",
    text: false,
  },
  {
    title: "NIK",
    text: false,
  },
  {
    title: "No.KK",
    text: false,
  },
  {
    title: "NISN",
    text: false,
  },
  {
    title: "Berkebutuhan Khusus",
    text: false,
  },
  {
    title: "Alamat Rumah",
    text: false,
  },
  {
    title: "Provinsi",
    text: false,
  },
  {
    title: "Kota / Kabupaten",
    text: false,
  },
  {
    title: "Kecamatan",
    text: false,
  },
  {
    title: "Desa / Kelurahan",
    text: false,
  },
  {
    title: "RT/RW",
    text: false,
  },
  {
    title: "Kode Pos",
    text: false,
  },
  {
    title: "Status Tempat Tinggal",
    text: false,
  },
  {
    title: "Moda Transportasi",
    text: false,
  },
  {
    title: "Kewarganegaraan",
    text: false,
  },
];

export const dataPeriodik = [
  {
    title: "Tinggi Badan",
  },
  {
    title: "Berat Badan",
  },
  {
    title: "Lingkar Kepala",
  },
  {
    title: "Golongan Darah",
  },
  {
    title: "Riwayat Panyakit",
  },
  {
    title: "Penyakit yang diderita",
  },
  {
    title: "Jarak rumah ke sekolah",
  },
  {
    title: "Waktu tempuh ke sekolah",
  },
];

export const faqSource = {
  faq: [
    {
      question: "Petunjuk pembayaran formulir",
      answer: <Petunjuk />,
    },
    {
      question: "Biaya pendidikan",
      answer: <Biaya />,
    },
    {
      question: "Daftar ulang",
      answer: <DaftarUlang />,
    },
  ],
};

export const accordion = {
  accrdn: [
    {
      title: "Formulir registrasi",
      content: <DownloadFormulir />,
    },
    {
      title: "Kartu peserta",
      content: <DownloadKartuPeserta />,
    },
    {
      title: "Surat pernyataan orang tua (MoU)",
      content: <DownloadMou />,
    },
  ],
};

// Data orang tua
export const dataAyah = [
  {
    title: "Nama Ayah",
    body: false,
  },
  {
    title: "NIK",
    body: false,
  },
  {
    title: "Tahun lahir",
    body: false,
  },
  {
    title: "Pendidikan terakhir",
    body: false,
  },
  {
    title: "Pekerjaan",
    body: false,
  },
  {
    title: "Penghasilan per bulan",
    body: false,
  },
  {
    title: "No. Telp",
    body: false,
  },
];
export const dataIbu = [
  {
    title: "Nama Ibu",
    body: false,
  },
  {
    title: "NIK",
    body: false,
  },
  {
    title: "Tahun lahir",
    body: false,
  },
  {
    title: "Pendidikan terakhir",
    body: false,
  },
  {
    title: "Pekerjaan",
    body: false,
  },
  {
    title: "Penghasilan per bulan",
    body: false,
  },
  {
    title: "No. Telp",
    body: false,
  },
];
export const dataWali = [
  {
    title: "Nama Wali",
    body: false,
  },
  {
    title: "NIK",
    body: false,
  },
  {
    title: "Tahun lahir",
    body: false,
  },
  {
    title: "Pendidikan terakhir",
    body: false,
  },
  {
    title: "Pekerjaan",
    body: false,
  },
  {
    title: "Penghasilan per bulan",
    body: false,
  },
  {
    title: "No. Telp",
    body: false,
  },
];
export const fotoHome = [
  {
    foto: "/images/tkit-sdit.png",
    alter: "Gambar TKIT dan SDIT"
  },
  {
    foto: "/images/mi-smpit.png",
    alter: "Gambar MI dan SMPIT"
  },
  {
    foto: "/images/mts-smait.png",
    alter: "Gambar MTS dan SMAIT"
  },
]