import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDataPeriodik } from "../api_folder/api";

const DataPeriodik = () => {

  const localUsrnm = localStorage.getItem("username");
  const [data, setData] = useState([]);

  useEffect(() => {
    getDataPeriodik(localUsrnm).then((res) => {
      setData(res.data);
    })
  },[localUsrnm])

  const dataPeriodik = [
    {
      title: "Tinggi Badan",
      text: data.height,
    },
    {
      title: "Berat Badan",
      text: data.weight,
    },
    {
      title: "Lingkar Kepala",
      text: data.head_size,
    },
    {
      title: "Golongan Darah",
      text: data.blood_type,
    },
    {
      title: "Riwayat Panyakit",
      text: data.disease_history,
    },
    {
      title: "Penyakit yang diderita",
      text: data.disease === '' ? 'Tidak Ada' : data.disease,
    },
    {
      title: "Jarak rumah ke sekolah",
      text: data.distance_house,
    },
    {
      title: "Waktu tempuh ke sekolah",
      text: data.traveling_time,
    },
  ];

  return (
    <section className="data-profile">
      <div className="position-relative">
        <Link
          to={"/dashboard/edit-dataPeriodik"}
          variant="light"
          className="btn-edit-dataDiri position-absolute top-0 end-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-edit fw-bold"
            width="100"
            height="100"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
            <path d="M16 5l3 3"></path>
          </svg>
          <span className="fw-500">Edit</span>
        </Link>
      </div>

      <div className="mt-5">
        <Table
          className="table-borderless"
          style={{ width: "325px", fontSize: "16px" }}
        >
          <tbody>
            {dataPeriodik?.map((e, i) => (
              <tr key={i}>
                <td>{e.title}</td>
                <td>
                  {e.text ? (
                    <span>{e.text}</span>
                  )
                : (
                  <button className="status-text">Belum Terisi</button>
                )}
                  
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default DataPeriodik;
