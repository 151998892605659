// import Button from "react-bootstrap/Button";
import "../styles/Home.css";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import { fotoHome } from "../data";
// import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Container className="mt ">
        <div className="position-relative">
          <div className="fCircle1"></div>
          <div className="fCircle2"></div>
        </div>
        <Row
          className="gap-5 justify-content-center align-items-end align-items-lg-center"
          style={{ minHeight: "80vh", zIndex: "99" }}
        >
          <Col
            lg={6}
            md={10}
            xs={11}
            style={{ zIndex: "99" }}
            className="ord1 z-999"
          >
            <div className="page1">
              <div className="px-4">
                <h2 style={{ color: "#364152" }} className="text-head-home">
                  Selamat Datang Calon Peserta Didik Baru
                  <span style={{ color: "#00923F" }}><br />
                    {" "}
                    Asy-Syukriyyah Islamic School
                  </span>
                </h2>

                <h6
                  style={{ color: "#697586" }}
                  className="mt-3 text-body-home"
                >
                  Asy-Syukriyyah Islamic School adalah sekolah Islam unggulan yang terletak di Kota Tangerang. Calon peserta didik baru dapat mendaftarkan diri secara online di website ini.
                </h6>
                {/* <Link to="/register">
                  <Button
                    variant="login"
                    className="rounded-pill mt-3"
                  >
                    Daftar Sekarang
                  </Button>
                </Link> */}
              </div>
            </div>
          </Col>

          <Col lg={5} md={6} xs={9} className="ord">
            <Carousel interval={2500} className="overflow-hidden" prevIcon nextIcon indicators={false} slide={false}>
              {fotoHome?.map((e, i) => (
                <Carousel.Item key={i}>
                  <img
                    className="d-block w-100"
                    src={e.foto}
                    alt={e.alter}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
