import "../styles/Contact.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Col, Container, Row } from "react-bootstrap";
// import { CallSchool } from "../data";
import { getContact } from "../api_folder/api";
import { useEffect, useState } from "react";
import * as md from "react-icons/md";

function Contact() {

  const [contacts, setContacts] = useState([]);

  const newData = {
    "id_unit": "12",
    "name_unit": "TKIT Asy-Syukriyyah",
    "logo_unit": "images/TUMBNAIL_TKIT.jpg",
    "email": "tkit@asy-syukriyyah.sch.id",
    "contact": "6282139045024",
    "sort_order": "1"
  }

  useEffect(() => {
    getContact().then((res) => {
      setContacts(res.data)
    })
  }, [])

  const filteredContacts = contacts.filter(contacts => contacts.id_unit >= 5 );
  filteredContacts.unshift(newData);

  return (
    <Container className="page4">
      <div className="text-center">
        <h1 className="title">Kontak</h1>
        <p style={{ color: "#697586" }}>
          Kontak program sekolah pilihan di Asy-Syukriyyah Islamic School
        </p>
      </div>
      {filteredContacts.length > 0 && (

        <Row className="gap-xxl-5 gap-xl-5 gap-lg-5 gap-md-5 justify-content-md-center flex-nowrap flex-md-wrap scroll-x p-3">
          {filteredContacts?.map((e, i) => (
            <Col xxl={3} xl={3} lg={4} md={5} xs={11} key={i}>
              <Card className="p-1 mx-auto list">
                <Card.Img
                  variant="top"
                  src={"https://ppdb.asy-syukriyyah.sch.id/" + e.logo_unit}
                  alt={e.name_unit}
                  style={{
                    height: "100%",
                    objectFit: "cover",
                    borderTopLeftRadius: '25px',
                    borderTopRightRadius: '25px',
                  }}
                />

                <Card.Body>
                  <Card.Title>{e.name_unit}</Card.Title>
                  <Card.Text className="my-2" style={{ color: "#697586" }}>
                    <md.MdOutlineEmail /> {e.email}
                  </Card.Text>
                  <Button
                    variant="success"
                    className="rounded-pill"
                    target="_blank"
                    href={`https://wa.me/+${e.contact}`}
                  >
                    Whatsapp
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}
export default Contact;
