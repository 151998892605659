import Axios from "axios";

const url = "https://ppdb.asy-syukriyyah.sch.id/api";

export const registerReg = (id, name, gender, birthday, birthplace, source_info, phone_number, email, other_school) => {
    return Axios({
      method: "post",
      url: url + "/request/register",
      headers: {
          "Content-Type": 'multipart/form-data',
      },
      data: {
        id_batch: id,
        name_student: name,
        gender: gender,
        birthday: birthday,
        birthplace: birthplace,
        source_information: source_info,
        phone_number: phone_number,
        email: email,
        others_school: other_school,
      },
    });
  };
export const registerPindahan = (id, name, gender, birthday, birthplace, source_info, phone_number, email, other_school) => {
    return Axios({
      method: "post",
      url: url + "/request/register-moving",
      headers: {
          "Content-Type": 'multipart/form-data',
      },
      data: {
        id_batch: id,
        name_student: name,
        gender: gender,
        birthday: birthday,
        birthplace: birthplace,
        source_information: source_info,
        phone_number: phone_number,
        email: email,
        others_school: other_school,
      },
    });
  };
export const registerInternal = (nis, id, name, gender, birthday, birthplace, source_info, phone_number, email, other_school) => {
    return Axios({
      method: "post",
      url: url + "/request/register-internal",
      headers: {
          "Content-Type": 'multipart/form-data',
      },
      data: {
        nis: nis,
        id_batch: id,
        name_student: name,
        gender: gender,
        birthday: birthday,
        birthplace: birthplace,
        source_information: source_info,
        phone_number: phone_number,
        email: email,
        others_school: other_school,
      },
    });
  };