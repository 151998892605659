import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as BsIcons from "react-icons/bs";
import "../styles/editForms.css";
import { getDataPersonal } from "../api_folder/api";
import { sendDataPersonal } from "../api_folder/api_sendData";

const FormProfile = () => {

  const [namaLengkap, setNamaLengkap] = useState('');
  const [nickname, setNickname] = useState('');
  const [anake, setAnake] = useState('');
  const [siblingSch, setSiblingSch] = useState('');
  const [siblingNum, setSiblingNum] = useState('');
  const [siblingYesNo, setSiblingYesNo] = useState('');
  const [nik, setNik] = useState('');
  const [nokk, setNoKK] = useState('');
  const [nisn, setNisn] = useState('');
  const [disability, setDisability] = useState('');
  const [address, setAddress] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [village, setVillage] = useState('');
  const [postal, setPostal] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [living, setLiving] = useState('');
  const [userfile, setUserfile] = useState('');
  const [rtrw, setRTRW] = useState('');
  const [transportation, setTransportation] = useState('');
  const [nationality, setNationality] = useState('');

  const [message, setMessage] = useState('');
  const [messageImg, setMessageImg] = useState('');
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameImg, setNameImg] = useState(null);

  const [fcek, setFCek] = useState(false);

  let selectedFile = null
  const localUsrnm = localStorage.getItem('username');

  const handleSubmit = (e) => {
    e.preventDefault()
    sendDataPersonal(localUsrnm, namaLengkap, nickname, nik, nokk, anake, siblingNum, address, rtrw, village, district, city, province, postal, phone, email, nationality, nisn, siblingSch, siblingYesNo, disability, living, transportation, userfile)
      .then((res) => {
        setMessage(res.data.message);
      })
  }

  const handleImage = (e) => {
    const file = e.target.files[0];
    if (file !== null) {
      setNameImg(file.name);
    } else if (file === null) {
      setNameImg('belum dipilih')
    }

    if (file) {
      setLoading(true)
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreview(reader.result)
        setLoading(false)
      }
      reader.readAsDataURL(file)
    }

    selectedFile = e.target.files;
    if (selectedFile[0].size / 1024 > 512) {
      setMessageImg("Ukuran file terlalu besar")
      return false;
    } else {
      setMessageImg("")
      let filez = null;
      let fileName = "";
      //Check File is not Empty
      if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        // eslint-disable-next-line
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        fileReader.onload = function (fileLoadedEvent) {
          filez = fileLoadedEvent.target.result;
          // Print data in console
          setUserfile(filez)
          // console.log(userfile);
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
    }
  }

  useEffect(() => {
    getDataPersonal(localUsrnm).then((res) => {
      setNamaLengkap(res.data.full_name);
      setNickname(res.data.nick_name);
      setAnake(res.data.child_no)
      setSiblingSch(res.data.sibling_school)
      setSiblingNum(res.data.sibling)
      setSiblingYesNo(res.data.sibling_school_yesno)
      setPhone(res.data.phone_number)
      setEmail(res.data.email)
      setLiving(res.data.living_status)
      setNik(res.data.nik)
      setNoKK(res.data.nokk)
      setNisn(res.data.nisn)
      setDisability(res.data.disability)
      setAddress(res.data.address)
      setProvince(res.data.name_province)
      setCity(res.data.name_city)
      setDistrict(res.data.name_district)
      setVillage(res.data.name_village)
      setPostal(res.data.postal_code)
      setRTRW(res.data.rt_rw)
      setTransportation(res.data.transportation)
      setNationality(res.data.nationality)
    })

  }, [localUsrnm])
  return (
    <section className="p-5 overflow-hidden">
      <Container className="position-relative">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="d-flex point gap-3 mb-2">
          <h2 className="fw-medium fs-2 ">Edit Data</h2>
        </div>
        <Link
          to={"/dashboard/profile"}
          className="mt-4"
          style={{ color: "#00923F" }}
        >
          <BsIcons.BsArrowLeft /> Kembali ke profile utama
        </Link>
        <Form className="mt-3" onSubmit={handleSubmit}>
          <h5 className="fw-medium text-dark">Data Pribadi</h5>
          <Row className="mt-4">
            <Col md={6}>
              <Form.Group className="mb-3" controlId="formFile">
                <h6 className="text-neutral">Foto Formal</h6>
                <Form.Label className="poto">
                  {preview ? (
                    <img
                      src={preview}
                      className="poto"
                      style={{
                        backgroundPosition: "center",
                        objectFit: "cover",
                      }}
                      alt="poto"
                    />
                  ) : (
                    <svg
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M58.3327 61.25V55.4167C58.3327 52.3225 57.1035 49.355 54.9156 47.1671C52.7277 44.9792 49.7602 43.75 46.666 43.75H23.3327C20.2385 43.75 17.271 44.9792 15.0831 47.1671C12.8952 49.355 11.666 52.3225 11.666 55.4167V61.25M46.666 20.4167C46.666 26.86 41.4427 32.0833 34.9993 32.0833C28.556 32.0833 23.3327 26.86 23.3327 20.4167C23.3327 13.9733 28.556 8.75 34.9993 8.75C41.4427 8.75 46.666 13.9733 46.666 20.4167Z"
                        stroke="#697586"
                        strokeWidth="8.75"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </Form.Label>
                <Form.Text
                  className="d-block ms-3"
                // style={{ marginTop: "-5px" }}
                >
                  Max file:512 KB (format file dalam bentuk jpg atau jpeg)
                </Form.Text>
                {messageImg && (
                  <Alert variant="danger">{messageImg}</Alert>
                )}
                {loading && (
                  <div className="loading-bar">
                    <div className="progress" />
                  </div>
                )}
                {nameImg}
                <input type="file" className="custom-file-input ms-4 mt-3" onChange={handleImage} />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Nama Lengkap</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan nama lengkap peserta didik"
                  value={namaLengkap}
                  onChange={e => setNamaLengkap(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  Nama panggilan peserta didik
                </Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan nama panggilan peserta didik"
                  value={nickname}
                  onChange={e => setNickname(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Anak Keberapa</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan angka"
                  value={anake}
                  onChange={e => setAnake(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  Jumlah saudara kandung
                </Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan jumlah saudara kandung"
                  value={siblingNum}
                  onChange={e => setSiblingNum(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Saudara kandung yang sedang bersekolah di Asy-Syukriyyah
                </Form.Label>
                <Form.Select required aria-label="Saudara" className="input-comp" value={siblingYesNo} onChange={e => setSiblingYesNo(e.target.value)}>
                  <option value="">Pilih Ya/Tidak</option>
                  <option value={1}>Ya</option>
                  <option value={0}>Tidak</option>
                </Form.Select>
              </Form.Group>
              {siblingYesNo === '1' ? (
                <Form.Group className="mb-3" controlId="textComp">
                  <Form.Label className="text-neutral">
                    Jika ada sebutkan
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    className="input-comp"
                    placeholder="Masukkan nama lengkap - unit pendidikan - kelas"
                    value={siblingSch}
                    onChange={e => setSiblingSch(e.target.value)}
                    required
                  />
                </Form.Group>
              ) : siblingYesNo === '0' ? (<></>) : <></>}
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  No. Telepon
                </Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan Nomor Telepon"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  className="input-comp"
                  placeholder="Masukkan Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  NIK
                </Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan Nomor NIK"
                  value={nik}
                  onChange={e => setNik(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  No. Kartu Keluarga &#40;KK&#41;
                </Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan Nomor KK"
                  value={nokk}
                  onChange={e => setNoKK(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  NISN
                </Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan nomor NISN"
                  value={nisn}
                  onChange={e => setNisn(e.target.value)}
                  required
                />
                <Form.Text className="text-muted">Masukkan 0 jika belum mempunyai NISN</Form.Text>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Berkebutuhan Khusus
                </Form.Label>
                <Form.Select required aria-label="Saudara" className="input-comp" value={disability} onChange={e => setDisability(e.target.value)}>
                  <option value="">Pilih Ya/Tidak</option>
                  <option value="Ya">Ya</option>
                  <option value="Tidak">Tidak</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Alamat Rumah</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan alamat peserta didik"
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">Provinsi</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan provinsi peserta didik"
                  value={province}
                  onChange={e => setProvince(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">Kota/Kabupaten</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan kota peserta didik"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">Kecamatan</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan kecamatan peserta didik"
                  value={district}
                  onChange={e => setDistrict(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">Desa/Kelurahan</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan desa peserta didik"
                  value={village}
                  onChange={e => setVillage(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">Kode Pos</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan kode pos peserta didik"
                  value={postal}
                  onChange={e => setPostal(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">RT/RW</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan RT/RW"
                  value={rtrw}
                  onChange={e => setRTRW(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Status Tempat Tinggal</Form.Label>
                <Form.Select required aria-label="transportasi" className="input-comp" value={living} onChange={e => setLiving(e.target.value)}>
                  <option value="">Pilih tempat tinggal</option>
                  <option value="Rumah Sendiri">Rumah Sendiri</option>
                  <option value="Rumah Kontrak">Rumah Kontrak</option>
                  <option value="Rumah Orang Tua">Rumah Orang Tua</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Mode Transportasi
                </Form.Label>
                <Form.Select required aria-label="transportasi" className="input-comp" value={transportation} onChange={e => setTransportation(e.target.value)}>
                  <option value="">Pilih mode transportasi</option>
                  <option value="Mobil Pribadi">Mobil Pribadi</option>
                  <option value="Kendaraan Umum">Kendaraan Umum</option>
                  <option value="Motor Pribadi">Motor Pribadi</option>
                  <option value="Sepeda">Sepeda</option>
                  <option value="Jalan Kaki">Jalan Kaki</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">
                  Kewarganegaraan
                </Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan kewarganegaraan"
                  value={nationality}
                  onChange={e => setNationality(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Check
                onChange={e => setFCek(e.target.checked)}
                type="checkbox"
                id="checkbox"
                label="Dengan ini saya menyatakan bahwa data yang saya isi adalah benar adanya. Jika dikemudian hari terbukti bahwa data tersebut tidak benar, maka saya bersedia didisklualifikasi dari proses PPDB Asy-Syukriyyah."
                style={{ color: "#697586" }}
              />
              <Row className="mt-2 gap-3 gap-md-0">
                {message && (
                  <Alert variant="info">{message}</Alert>
                )}
                <Col sm={6}>
                  <Button variant="form-edit" className="simpan-next" type="submit" disabled={!fcek}>
                    Simpan
                  </Button>
                </Col>
                <Col sm={6}>
                  <Button disabled variant="form-edit" className=" selesai">
                    Selesai
                  </Button>
                </Col>
                <Col className="mt-lg-4">
                  <Link to='/dashboard/edit-dataPeriodik'>
                    <Button variant="form-edit" className="simpan-next">
                      Selanjutnya
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  );
};

export default FormProfile;
