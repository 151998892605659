import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDataParent } from "../api_folder/api";

const DataWali = () => {

  const localUsrnm = localStorage.getItem('username');

  const [data, setData] = useState([]);

  useEffect(() => {
    getDataParent(localUsrnm).then((res) => {
      setData(res.data);
    })
  }, [localUsrnm])

  const dataAyah = [
    {
      title: "Nama Ayah",
      body: data.name_parent_0,
    },
    {
      title: "NIK",
      body: data.nik_0,
    },
    {
      title: "Tahun lahir",
      body: data.birth_year_0,
    },
    {
      title: "Pendidikan terakhir",
      body: data.education_0,
    },
    {
      title: "Pekerjaan",
      body: data.work_0,
    },
    {
      title: "Penghasilan per bulan",
      body:
        data.salary_0 === '1' ? 'Dibawah 5jt'
          : data.salary_0 === '2' ? '5jt - 10jt'
            : data.salary_0 === '3' ? '10jt - 20jt'
              : data.salary_0 === '4' ? 'Diatas 20jt'
                : '',
    },
    {
      title: "No. Telp",
      body: data.phone_number_0,
    },
  ];
  const dataIbu = [
    {
      title: "Nama Ibu",
      body: data.name_parent_1,
    },
    {
      title: "NIK",
      body: data.nik_1,
    },
    {
      title: "Tahun lahir",
      body: data.birth_year_1,
    },
    {
      title: "Pendidikan terakhir",
      body: data.education_1,
    },
    {
      title: "Pekerjaan",
      body: data.work_1,
    },
    {
      title: "Penghasilan per bulan",
      body:
        data.salary_1 === '1' ? 'Dibawah 5jt'
          : data.salary_1 === '2' ? '5jt - 10jt'
            : data.salary_1 === '3' ? '10jt - 20jt'
              : data.salary_1 === '4' ? 'Diatas 20jt'
                : '',
    },
    {
      title: "No. Telp",
      body: data.phone_number_1,
    },
  ];
  const dataWali = [
    {
      title: "Nama Wali",
      body: data.name_parent_2,
    },
    {
      title: "NIK",
      body: data.nik_2,
    },
    {
      title: "Tahun lahir",
      body: data.birth_year_2,
    },
    {
      title: "Pendidikan terakhir",
      body: data.education_2,
    },
    {
      title: "Pekerjaan",
      body: data.work_2,
    },
    {
      title: "Penghasilan per bulan",
      body:
        data.salary_2 === '1' ? 'Dibawah 5jt'
          : data.salary_2 === '2' ? '5jt - 10jt'
            : data.salary_2 === '3' ? '10jt - 20jt'
              : data.salary_2 === '4' ? 'Diatas 20jt'
                : '',
    },
    {
      title: "No. Telp",
      body: data.phone_number_2,
    },
  ];

  return (
    <section className="data-profile">
      <div className="position-relative">
        <Link
          to={"/dashboard/edit-orangTua"}
          variant="light"
          className="btn-edit-dataDiri position-absolute top-0 end-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-edit fw-bold"
            width="100"
            height="100"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
            <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
            <path d="M16 5l3 3"></path>
          </svg>
          <span className="fw-500">Edit</span>
        </Link>
      </div>
      <div className="mt-5">

        {/* WORK IN PROGRESS */}
        <h6 className="fw-medium">Data {data.parent_type_0}</h6>
        <Table
          className="table-borderless"
          style={{ width: "325px", fontSize: "16px" }}
        >
          <tbody>
            {dataAyah?.map((e, i) => (
              <tr key={i}>
                <td style={{ fontSize: "16px" }}>{e.title}</td>
                <td>
                  {e.body ? (
                    <span>{e.body}</span>
                  ) : (
                    <button className="status-text">Belum Terisi</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h6 className="fw-medium">Data {data.parent_type_1}</h6>
        <Table
          className="table-borderless"
          style={{ width: "325px", fontSize: "16px" }}
        >
          <tbody>
            {dataIbu?.map((e, i) => (
              <tr key={i}>
                <td style={{ fontSize: "16px" }}>{e.title}</td>
                <td>
                  {e.body ? (
                    <span>{e.body}</span>
                  ) : (
                    <button className="status-text">Belum Terisi</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <h6 className="fw-medium">Data Wali (Opsional)</h6>
        <Table
          className="table-borderless"
          style={{ width: "325px", fontSize: "16px" }}
        >
          <tbody>
            {dataWali?.map((e, i) => (
              <tr key={i}>
                <td style={{ fontSize: "16px" }}>{e.title}</td>
                <td>
                  {e.body ? (
                    <span>{e.body}</span>
                  ) : (
                    <button className="status-text">Belum Terisi</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default DataWali;
