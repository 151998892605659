import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { downloadAgree } from "../api_folder/api";

const DownloadMou = () => {
  const localUsrname = localStorage.getItem('username')
  const [download, setDownload] = useState('')
  useEffect(() => {
    downloadAgree(localUsrname).then((res) => {
      setDownload(res.data.link_download)
    })
  }, [localUsrname])
  return (
    <div className="p-4">
      <h5>Surat pernyataan orang tua (MoU)</h5>
      <p className="text-muted">
        Jika sudah mengisi formulir registrasi, maka Anda dapat mendownload
        surat pernyataan di bawah ini:
      </p>
      <div className="poto mx-auto mt-3">
        <svg
          width="90"
          height="90"
          viewBox="0 0 70 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="file">
            <path
              id="Icon"
              d="M37.916 5.83398H17.4993C15.9523 5.83398 14.4685 6.44857 13.3746 7.54253C12.2806 8.63649 11.666 10.1202 11.666 11.6673V58.334C11.666 59.8811 12.2806 61.3648 13.3746 62.4588C14.4685 63.5527 15.9523 64.1673 17.4993 64.1673H52.4994C54.0464 64.1673 55.5302 63.5527 56.6241 62.4588C57.7181 61.3648 58.3327 59.8811 58.3327 58.334V26.2506M37.916 5.83398L58.3327 26.2506M37.916 5.83398V26.2506H58.3327"
              stroke="#697586"
              strokeWidth="8.75"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </div>
      <Button variant="unduh" className="d-block mt-3 mx-auto w-50" href={download} target="_blank">
        Download Disini
      </Button>
    </div>
  );
};

export default DownloadMou;
