import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import '../styles/Register.css';
import * as BsIcons from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Form, InputGroup, Alert } from "react-bootstrap";
function RegisterSuccess() {
    const l = useLocation();

    return (
        <section className="p-3">
            <Navbar
                expand="sm"
                variant="custom"
                bsPrefix={""}
                fixed="top"
            >
                <Navbar.Brand>
                    <img
                        src="/images/logo.svg"
                        width="44"
                        height="45"
                        className="d-inline-block align-top ms-3"
                        alt="logo"
                    />
                </Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link
                        className="me-3"
                    >
                        PPDB Asy-Syukriyyah Islamic School
                    </Nav.Link>
                </Nav>
            </Navbar>
            <Container style={{ marginTop: '126px', marginBottom: '120px' }}>
                <Link to="/" className="back" >
                    <BsIcons.BsArrowLeftShort /> Kembali ke halaman utama
                </Link>


                <span className="circlex" />
                <span className="circley" />
                <div className="mt-4">
                    <div style={{ color: '#00923F' }}>
                        <BsIcons.BsDatabaseCheck size={70} />
                        <h1 style={{ color: '#364152', marginTop: '3rem' }}>Pendaftaran sukses!</h1>
                    </div>
                    <Alert variant="info">{l.state.message}</Alert>
                    <Form.Label column sm="2">
                        Username
                    </Form.Label>
                    <Form.Control defaultValue={l.state.username} className="w-100" readOnly type="text" style={{ marginTop: '0.5rem' }} />
                    <Form.Label column sm="2">
                        Password
                    </Form.Label>
                    <Form.Control defaultValue={l.state.password} className="w-100" readOnly type="text" style={{ marginTop: '0.5rem' }} />
                    <Form.Label column sm="2">
                        Pembayaran
                    </Form.Label>
                    <Form.Control defaultValue={'Infradigital'} className="w-100" readOnly type="text" style={{ marginTop: '0.5rem' }} />
                    <Form.Label column sm="2">
                        Nominal
                    </Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Rp.</InputGroup.Text>
                        <Form.Control defaultValue={new Intl.NumberFormat('en-ID', { maximumSignificantDigits: 3 }).format(l.state.nominal)} aria-label="Amount (to the nearest dollar)" readOnly />
                    </InputGroup>
                    <Alert variant="success">Silakan screenshot layar ini atau klik tombol dibawah ini:</Alert>
                    <Button target="_blank" href={l.state.link_download} variant="success" style={{ marginTop: '0.5rem' }}>Print</Button>

                    <p className="text-body-regsuccess">Silahkan membayar formulir pendaftaran sebelum melanjutkan proses melengkapi data diri. Berikut langkah-langkah pembayaran formulir PPDB Reguler.</p>

                    <Row className="gap-4 mt-1 px-4">
                        <Col xs={12} className="infoBox">
                            <h6 style={{ color: '#364152' }}>📝 Ketentuan umum</h6>
                            <ul>
                                <li>Masing-masing pendaftar akan mendapatkan nomor pendaftaran.</li>
                                <li>Pembayaran biaya pendaftaran dilakukan melalui portal IDN (infradigital).</li>
                                <li>Biaya pendaftaran sebagaimana tertera pada bukti pendaftaran ditambah biaya admin.</li>
                                <li>Pembayaran biaya pendaftaran paling lambat 1 hari terhitung dari hari pendaftaran.</li>
                            </ul>

                        </Col>
                        <Col xs={12} lg={7} className="infoBox">
                            <h6 style={{ color: '#364152' }}>📄 Langkah-langkah pembayaran formulir registrasi reguler</h6>
                            <ol>
                                <li>Cek tagihan biaya formulir anda di <Link to={'https://www.infradigital.io/bills'} style={{ color: '#00923F' }}>https://www.infradigital.io/bills</Link></li>
                                <li>Pilih unit pendidikan di kolom <b>“-Pilih Sekolah-”</b></li>
                                <li>Masukkan nomor pendaftaran di kolom <b>“Masukkan Nomor Induk Siswa (NIS)”</b></li>
                                <li>Klik tombol <b>“Lihat Tagihan”</b></li>
                                <li>Pastikan nominal tagihan dan nomor pendaftaran sudah sesuai (ada tambahan biaya admin tergantung pilihan pembayaran yg dipilih)</li>
                                <li>Pilih <b>“Cara bayar yang Anda kehendaki”</b></li>
                                <li>Klik <b>"Lihat Cara Bayar"</b></li>
                                <li>Ikuti petunjuk pembayaran sesuai yang tertera di <Link to={'https://www.infradigital.io/bills'} style={{ color: '#00923F' }}>infradigital.io</Link></li>
                                <li>Konfirmasikan pembayaran biaya pendaftaran anda ke Call Center unit pendidikan terkait untuk aktivasi akun.</li>
                            </ol>
                        </Col>
                        <Col xs={12} lg={4} className='infoBox'>
                            <h6 style={{ color: '#364152' }}>📅 Kode unit pendidikan</h6>
                            <ul>
                                <li><span className="primColor">10620</span> - TK Islam Terpadu Asy-Syukriyyah</li>
                                <li><span className="primColor">10610</span> - MI Plus Asy-Syukriyyah</li>
                                <li><span className="primColor">10619</span> - SD Islam Terpadu Asy-Syukriyyah</li>
                                <li><span className="primColor">10697</span> - SDIT Quran Asy-Syukriyyah Faturrahman</li>
                                <li><span className="primColor">10622</span> - SMP Islam Terpadu Asy-Syukriyyah</li>
                                <li><span className="primColor">10623</span> - MTs Plus Asy-Syukriyyah</li>
                                <li><span className="primColor">10621</span> - SMA Islam Terpadu Asy-Syukriyyah</li>
                            </ul>
                        </Col>

                    </Row>
                </div>
            </Container>

        </section>
    )

}
export default RegisterSuccess;