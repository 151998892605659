import "../styles/OurProgram.css";
import Card from "react-bootstrap/Card";
import Badge from 'react-bootstrap/Badge';
import { Col, Container, Row } from "react-bootstrap";
// import { SchoolProgram } from "../data";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { getUnit } from "../api_folder/api";

function OurProgram() {
  const [units, setUnits] = useState([]);

  const newData = {
    "id_unit": "4",
    "name_unit": "TKIT Asy-Syukriyyah",
    "logo_unit": "images/TUMBNAIL_TKIT.jpg",
    "url": "/unit-tkit",
    "sort_order": "1"
  }

  const newData1 = {
    "id_unit": "10",
    "grup_unit": "7",
    "name_unit": "SDIT Quran Asy-Syukriyyah Faturrahman",
    "logo_unit": "images/TUMBNAIL_ASYFA.jpg",
    "url": "/unit-sdit1",
    "sort_order": "10"
  }

  useEffect(() => {

    getUnit().then((res) => {
      setUnits(res.data);
    })

  }, [])
  // filter to decrease card
  const filteredUnits = units.filter(unit => unit.id_unit >= 5 && unit.id_unit !== "11" && unit.id_unit !== "10");
  filteredUnits.unshift(newData);
  filteredUnits.push(newData1);




  return (
    <Container className="page2">
      <div>
        <h1 className="title text-center">Program kami</h1>

        <p className="text-center" style={{ color: "#697586" }}>
          Program sekolah pilihan di Asy-Syukriyyah Islamic School
        </p>
      </div>
      {filteredUnits.length > 0 && (

        <Row className="gap-xxl-5 gap-xs-5 gap-md-4 justify-content-md-center flex-nowrap flex-md-wrap scroll-x p-3">

          {filteredUnits?.map((e, i) => (
            <Col xs={11} md={5} lg={4} xl={3} xxl={3} key={i} className="p-2">
              <Link
                to={e.url}
                className="d-block p-1 mx-auto card list"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <Card.Img
                  variant="top"
                  src={"https://ppdb.asy-syukriyyah.sch.id/" + e.logo_unit}
                  alt={e.name_unit}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    position: "relative",
                    borderTopLeftRadius: '25px',
                    borderTopRightRadius: '25px',
                  }}
                />
                <Card.Body>
                  <Card.Title>{e.name_unit}</Card.Title>
                  <Card.Text>

                  </Card.Text>
                  <Badge pill bg="success">Daftar</Badge>
                </Card.Body>
              </Link>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}
export default OurProgram;
