import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import '../styles/Register.css';
import * as BsIcons from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { Button, Container, Form, Alert } from "react-bootstrap";
function RegisterInternalSuccess() {
    const l = useLocation();

    return (
        <section className="p-3">
            <Navbar
                expand="sm"
                variant="custom"
                bsPrefix={""}
                fixed="top"
            >
                <Navbar.Brand>
                    <img
                        src="/images/logo.svg"
                        width="44"
                        height="45"
                        className="d-inline-block align-top ms-3"
                        alt="logo"
                    />
                </Navbar.Brand>
                <Nav className="ms-auto">
                    <Nav.Link
                        className="me-3"
                    >
                        PPDB Asy-Syukriyyah Islamic School
                    </Nav.Link>
                </Nav>
            </Navbar>
            <Container style={{ marginTop: '126px', marginBottom: '120px' }}>
                <Link to="/" className="back" >
                    <BsIcons.BsArrowLeftShort /> Kembali ke halaman utama
                </Link>


                <span className="circlex" />
                <span className="circley" />
                <div className="mt-4">
                    <div style={{ color: '#00923F' }}>
                        <BsIcons.BsCheck2 size={100} />
                        <h1 style={{ color: '#364152', marginTop: '0.5rem' }}>Pendaftaran sukses!</h1>
                    </div>
                    {/* <p className="text-body-regsuccess">Pendaftaran internal telah sukses, Data informasi pendaftaran akan dikirim melalui email (cek inbox/spam).</p> */}
                    <Alert className="mt-3" variant="success">{l.state.message}</Alert>
                    <Form.Label column sm="2">
                        Username
                    </Form.Label>
                    <Form.Control defaultValue={l.state.username} className="w-100" readOnly type="text" style={{ marginTop: '0.5rem' }} />
                    <Form.Label column sm="2">
                        Password
                    </Form.Label>
                    <Form.Control defaultValue={l.state.password} className="w-100" readOnly type="text" style={{ marginTop: '0.5rem' }} />
                    <Alert variant="success" style={{ marginTop: '1rem' }}>Silakan screenshot layar ini atau klik tombol dibawah ini:</Alert>
                    <Button target="_blank" href={l.state.link_download} variant="success" style={{ marginTop: '1.0rem' }}>Print</Button>
                    <Link to={'/login'}>
                        <Button variant="success" style={{ marginTop: '1.0rem' }}>Login</Button>
                    </Link>
                </div>
            </Container>

        </section>
    )

}
export default RegisterInternalSuccess;