import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import { Navbar, Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/favicon.ico";
import "../styles/dashboard.css";
import * as BsIcons from "react-icons/bs";
import * as GiIcons from "react-icons/gi";

const Dashboard = () => {
  const ltest = localStorage.getItem('username');
  const navi = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const [toggle, setToggle] = useState(false);
  const sidebar = () => {
    if (toggle === true) {
      setToggle(false);
    }
  };

  useEffect(() => {
    if (ltest === null) navi('/login')
  })

  return (
    <section>
      <Navbar variant="customLogin" style={{ height: "99px" }}>
        <Container fluid>
          <NavLink
            to="/dashboard"
            className="text-white d-flex align-items-center"
          >
            <div style={{ width: "38px" }}>
              <img
                alt="logo sekolah"
                src={logo}
                className="d-inline-block align-top img-fluid"
              />{" "}
            </div>
            <p>PPDB Asy-Syukriyyah Islamic School Dashboard</p>
          </NavLink>
        </Container>
      </Navbar>
      <div className="d-flex">
        <aside
          className={`${toggle ? "side" : "w-inherit"
            } h-full side-res transition min-vh-100`}
          style={{ zIndex: '9090' }}
        >
          <div
            style={{ height: "86vh" }}
            className=" d-flex align-items-center flex-column justify-content-between"
          >

            <ul className="list-unstyled d-flex flex-column justify-content-center gap-4 mt-5">
              <li className="position-relative">
                <Link
                  to={"/dashboard"}
                  className={`${pathname === '/dashboard' ? 'active-side text-dark fw-medium' : ''} d-flex flex-row p-1 transition align-items-center gap-2 link-side ${toggle ? "jtc p-2 w-auto" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/"
                    className={`${toggle ? "scale" : ""
                      } icon-side icon icon-tabler icon-tabler-category-2 ms-2`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M14 4h6v6h-6z"></path>
                    <path d="M4 14h6v6h-6z"></path>
                    <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                    <path d="M7 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                  </svg>
                  <p className={`${toggle ? "d-non" : "d-inhe"} `}>Dashboard</p>
                </Link>
              </li>
              <li className="position-relative">
                <Link
                  to={"profile"}
                  className={`${pathname === '/dashboard/profile' || pathname === '/dashboard/edit-profile' || pathname === '/dashboard/edit-dataPeriodik' || pathname === '/dashboard/edit-orangTua' || pathname === '/dashboard/edit-kk' ? 'active-side text-dark fw-medium' : ''} d-flex flex-row p-1 transition align-items-center gap-2 link-side ${toggle ? "jtc p-2 w-auto" : ""}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${toggle ? "scale" : ""
                      }icon-side icon icon-tabler icon-tabler-user ms-2`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                    <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                  </svg>

                  <p className={`${toggle ? "d-non" : "d-inhe"} `}>Profile</p>
                </Link>
              </li>
              <li className="position-relative">
                <Link
                  to={"pembayaran"}
                  className={`${pathname === '/dashboard/pembayaran' ? 'active-side text-dark fw-medium' : ''} d-flex flex-row p-1 transition align-items-center gap-2 link-side ${toggle ? "jtc p-2 w-auto" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${toggle ? "scale" : ""
                      } icon-side icon icon-tabler icon-tabler-credit-card ms-2`}
                    width="27"
                    height="27"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                    <path d="M3 10l18 0"></path>
                    <path d="M7 15l.01 0"></path>
                    <path d="M11 15l2 0"></path>
                  </svg>

                  <p className={`${toggle ? "d-non" : "d-inhe"} fs-6`}>
                    Informasi Pembayaran
                  </p>
                </Link>
              </li>
              <li className="position-relative">
                <Link
                  to={"download"}
                  className={`${pathname === '/dashboard/download' ? 'active-side text-dark fw-medium' : ''} d-flex flex-row p-1 transition align-items-center gap-2 link-side ${toggle ? "jtc p-2 w-auto" : ""
                    }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${toggle ? "scale" : ""
                      } icon-side ms-2 icon icon-tabler icon-tabler-download`}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                    <path d="M7 11l5 5l5 -5"></path>
                    <path d="M12 4l0 12"></path>
                  </svg>
                  <p className={`${toggle ? "d-non" : "d-inhe"} fs-6`}>
                    Download Dokumen
                  </p>
                </Link>
              </li>
            </ul>
            <div className="position-relative">
              <Link
                to={"/"}
                className={`d-flex flex-row p-1 transition align-items-center gap-2 link-side ${toggle ? "jtc p-2 width-auto" : ""}`}
                onClick={() => {
                  localStorage.removeItem("token")
                  localStorage.removeItem("username")
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`${toggle ? "scale" : ""
                    } icon-side ms-2 icon icon-tabler icon-tabler-logout`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                  <path d="M9 12h12l-3 -3"></path>
                  <path d="M18 15l3 -3"></path>
                </svg>
                <p className={`${toggle ? "d-non" : "d-inhe"} fs-6`} onClick={() => {
                  localStorage.removeItem("token")
                  localStorage.removeItem("username")
                }}>Keluar</p>
              </Link>
            </div>
          </div>
        </aside>
        <main className="w-100 " onClick={sidebar}>
          <nav className="position-relative bg-info">
            <div
              className="position-absolute d-grid rounded-circle left z-999"
              onClick={() => setToggle(!toggle)}
              style={{
                top: "5px",

                width: "30px",
                height: "30px",
                background: "#EEF2F6",
                placeItems: "center",
                cursor: "pointer",
              }}
            >
              {toggle ? <BsIcons.BsChevronRight className="sm-none" /> : <BsIcons.BsChevronLeft className="sm-none" />}
              <GiIcons.GiHamburgerMenu className="xl-none" />
            </div>
          </nav>
          <Outlet />
        </main>
      </div>
    </section>
  );
};

export default Dashboard;
