import { Link } from "react-router-dom";

const Test2 = () => {
  return (
    <div className="mt-4">
      <h6 style={{ color: "#364152" }}>
        Petunjuk pembayaran formulir registrasi online
      </h6>
      <div className="d-flex gap-3 flex-column">
        <div className="infoBox">
          <h6 style={{ color: "#364152" }}>📝Persyaratan Umum</h6>
          <ul>
            <li>
              Pendaftar yang dinyatakan <b>“Lulus”</b> akan mendapatkan nomor
              pembayaran baru yang berbeda dengan nomor pembayaran Formulir.
            </li>
            <li>
              Pembayaran biaya daftar ulang dilakukan melalui portal
              Infradigital Nusantara (IDN).
            </li>
            <li>
              Biaya daftar ulang sebagaimana tertera pada tagihan IDN, ditambah
              biaya admin.
            </li>
            <li>
              Pembayaran biaya daftar ulang sesuai jadwal yang telah ditentukan.
            </li>
          </ul>
        </div>
        <div className="infoBox">
          <h6 style={{ color: "#364152" }}>
            📑 Langkah-langkah pembayaran formulir registrasi reguler
          </h6>
          <ol>
            <li>
              Cek tagihan biaya formulir anda di{" "}
              <Link
                to={"https://www.infradigital.io/bills"}
                style={{ color: "#00923F" }}
              >
                https://www.infradigital.io/bills
              </Link>
            </li>
            <li>
              Pilih unit pendidikan di kolom <b>“-Pilih Sekolah-”</b>
            </li>
            <li>
              Masukkan nomor pendaftaran di kolom{" "}
              <b>“Masukkan Nomor Induk Siswa (NIS)”</b>
            </li>
            <li>
              Klik tombol <b>“Lihat Tagihan”</b>
            </li>
            <li>
              Pastikan nominal tagihan dan nomor pendaftaran sudah sesuai (ada
              tambahan biaya admin tergantung pilihan pembayaran yg dipilih)
            </li>
            <li>
              Pilih <b>“Cara bayar yang Anda kehendaki”</b>
            </li>
            <li>
              Klik <b>"Lihat Cara Bayar"</b>
            </li>
            <li>
              Ikuti petunjuk pembayaran sesuai yang tertera di{" "}
              <Link
                to={"https://www.infradigital.io/bills"}
                style={{ color: "#00923F" }}
              >
                infradigital.io
              </Link>
            </li>
            <li>
              Konfirmasikan pembayaran biaya daftar ulang anda ke Call Center
              Keuangan Yayasan Islam Asy-Syukriyyah. <b>0811-1544-033</b> dan{" "}
              <b>0811-3222-731</b> (hanya pesan whatsapp)
            </li>
          </ol>
        </div>
        <div className="infoBox">
          <h6 style={{ color: "#364152" }}>🗒️ Kode unit pendidikan</h6>
          <ul>
            <li>
              <span className="primColor">10620</span> - TK Islam Terpadu
              Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10610</span> - MI Plus Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10619</span> - SD Islam Terpadu
              Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10697</span> - SDIT Quran
              Asy-Syukriyyah Faturrahman
            </li>
            <li>
              <span className="primColor">10622</span> - SMP Islam Terpadu
              Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10623</span> - MTs Plus Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10621</span> - SMA Islam Terpadu
              Asy-Syukriyyah
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Test2;
