import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styles/Register.css";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
function RegisterMainPage() {
  return (
    <section className="p-3">
      <Navbar expand="sm" variant="custom" bsPrefix={""} fixed="top">
        <Navbar.Brand>
          <img
            src="/images/logo.svg"
            width="44"
            height="45"
            className="d-inline-block align-top ms-3"
            alt="logo"
          />
        </Navbar.Brand>
        <Nav className="ms-auto">
          <Nav.Link className="me-3">
            PPDB Asy-Syukriyyah Islamic School
          </Nav.Link>
        </Nav>
      </Navbar>
      <div className="mb-5 position-relative">
        <div className="forText">
          <a href="/" className="back">
            <BsIcons.BsArrowLeftShort />
            Kembali ke halaman utama
          </a>
        </div>
      </div>
      <Container className="v-screen d-flex justify-content-center align-items-center">
        <Row className=" justify-content-center row-regis w-100 align-items-center">
          <Col xs={11} md={6} lg={4}>
            <Link
              to="/register-internal"
              style={{
                textDecoration: "none",
              }}
            >
              <div className="daBox">
                <span className="spanTitle">Internal</span>
                <p className="pCaption mt-2" align="center">
                  Pendaftaran untuk siswa/i Asy-Syukriyyah yang ingin
                  melanjutkan ke jenjang berikutnya{" "}
                </p>
              </div>
            </Link>
          </Col>
          <Col xs={11} md={6} lg={4}>
            <Link to="/register-reguler" style={{ textDecoration: "none" }}>
              <div className="daBox">
                <span className="spanTitle">Reguler</span>
                <p className="pCaption mt-2" align="center">
                  Pendaftaran reguler siswa baru Asy-Syukriyyah
                </p>
              </div>
            </Link>
          </Col>
          <Col xs={11} md={6} lg={4}>
            <Link to="/register-pindahan" style={{ textDecoration: "none" }}>
              <div className="daBox">
                <span className="spanTitle">Pindahan</span>
                <p className="pCaption mt-2" align="center">
                  Pendaftaran untuk siswa pindahan,harap konfirmasi ketersediaan
                  kuota unit terlebih dahulu
                </p>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>

      <span className="circlex" />
      <span className="circley" />
    </section>
  );
}
export default RegisterMainPage;
