import Axios from "axios";

const url = "https://ppdb.asy-syukriyyah.sch.id/api";

// request
export function getUnit() {
  return Axios({
    method: "get",
    url: url + "/request/unit",
    headers: {},
  });
}

export function getContact() {
  return Axios({
    method: "get",
    url: url + "/request/contact",
    headers: {},
  });
}

export function getInternalActiveBatch() {
  return Axios({
    method: "get",
    url: url + "/request/fetchBatchInternalActive",
    headers: {},
  });
}
export function getActiveBatch() {
  return Axios({
    method: "get",
    url: url + "/request/fetchBatchActive",
    headers: {},
  });
}
export function getMovingActiveBatch() {
  return Axios({
    method: "get",
    url: url + "/request/fetchBatchMoving",
    headers: {},
  });
}

export const getUserInternal = async (nis) => {
  return Axios({
    method: "get",
    url: url + `/request/getUserInternal?nis=${nis}`,
    headers: {},
  });
};
// post
export const ceknis = (nisdata) => {
  return Axios({
    method: "post",
    url: url + "/request/ceknis",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      nis: nisdata,
    },
  });
};

export const login = (usernm, passwd) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/login",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
      password: passwd,
    },
  });
};

export const getDataPersonal = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/getDataPersonal",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const getDataForm = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/getDataForm",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const cekProsesPendaftaran = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/checkProsesPendaftaran",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const getDataPeriodik = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/getDataPeriod",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const getDataParent = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/getDataParent",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const cekStatusLulus = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/cekStatusLulus",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const downloadAgree = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/download-agreement",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const downloadForm = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/download-form",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const downloadCard = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/download-card",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
export const cekBiaya = (usernm) => {
  return Axios({
    method: "post",
    url: url + "/ppdb/cekBiayaPendidikan",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      username: usernm,
    },
  });
};
