import { Link } from "react-router-dom";

const Test = () => {
  return (
    <div className="mt-4">
      <h6 className="p-2" style={{ color: "#364152" }}>
        Petunjuk pembayaran formulir registrasi online
      </h6>
      <div className="d-flex gap-3 flex-column">
        <div className="infoBox">
          <h6 style={{ color: "#364152" }}>📝Persyaratan Umum</h6>
          <ul>
            <li>Masing-masing pendaftar akan mendapatkan nomor pendaftaran.</li>
            <li>
              Pembayaran biaya pendaftaran dilakukan melalui portal IDN
              (infradigital).
            </li>
            <li>
              Biaya pendaftaran sebagaimana tertera pada bukti pendaftaran
              ditambah biaya admin.
            </li>
            <li>
              Pembayaran biaya pendaftaran paling lambat 1 hari terhitung dari
              hari pendaftaran.
            </li>
          </ul>
        </div>
        <div className="infoBox">
          <h6 style={{ color: "#364152" }}>
            📑 Langkah-langkah pembayaran formulir registrasi reguler
          </h6>
          <ol>
            <li>
              Cek tagihan biaya formulir anda di{" "}
              <Link
                to={"https://www.infradigital.io/bills"}
                style={{ color: "#00923F" }}
              >
                https://www.infradigital.io/bills
              </Link>
            </li>
            <li>
              Pilih unit pendidikan di kolom <b>“-Pilih Sekolah-”</b>
            </li>
            <li>
              Masukkan nomor pendaftaran di kolom{" "}
              <b>“Masukkan Nomor Induk Siswa (NIS)”</b>
            </li>
            <li>
              Klik tombol <b>“Lihat Tagihan”</b>
            </li>
            <li>
              Pastikan nominal tagihan dan nomor pendaftaran sudah sesuai (ada
              tambahan biaya admin tergantung pilihan pembayaran yg dipilih)
            </li>
            <li>
              Pilih <b>“Cara bayar yang Anda kehendaki”</b>
            </li>
            <li>
              Klik <b>"Lihat Cara Bayar"</b>
            </li>
            <li>
              Ikuti petunjuk pembayaran sesuai yang tertera di{" "}
              <Link
                to={"https://www.infradigital.io/bills"}
                style={{ color: "#00923F" }}
              >
                infradigital.io
              </Link>
            </li>
            <li>
              Konfirmasikan pembayaran biaya pendaftaran anda ke Call Center
              unit pendidikan terkait untuk aktivasi akun.
            </li>
          </ol>
        </div>
        <div className="infoBox">
          <h6 style={{ color: "#364152" }}>🗒️ Kode unit pendidikan</h6>
          <ul>
            <li>
              <span className="primColor">10620</span> - TK Islam Terpadu
              Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10610</span> - MI Plus Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10619</span> - SD Islam Terpadu
              Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10697</span> - SDIT Quran
              Asy-Syukriyyah Faturrahman
            </li>
            <li>
              <span className="primColor">10622</span> - SMP Islam Terpadu
              Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10623</span> - MTs Plus Asy-Syukriyyah
            </li>
            <li>
              <span className="primColor">10621</span> - SMA Islam Terpadu
              Asy-Syukriyyah
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Test;
