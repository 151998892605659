import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "../styles/Unit.css";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
import { getActiveBatch, getMovingActiveBatch, getInternalActiveBatch } from "../api_folder/api";
import { useEffect, useState } from "react";

function UnitMIPlus() {

    const [reg, setReg] = useState([]);
    const [inter, setInter] = useState([]);
    const [moving, setMoving] = useState([]);

    useEffect(() => {
        getActiveBatch().then((res) => {
            setReg(res.data);
        })
        getInternalActiveBatch().then((res) => {
            setInter(res.data);
        })
        getMovingActiveBatch().then((res) => {
            setMoving(res.data);
        })
    }, [])

    return (
        <section>
            <Navbar expand="sm" variant="custom" bsPrefix={""} sticky="top">
                <Navbar.Brand>
                    <img
                        src="/images/logo.svg"
                        width="44"
                        height="45"
                        className="d-inline-block align-top ms-3"
                        alt="logo"
                    />
                </Navbar.Brand>
                <Nav>
                    <Nav.Link>PPDB Asy-Syukriyyah Islamic School</Nav.Link>
                </Nav>
            </Navbar>
            <Container className="p-3 p-md-5">
                <Link to="/" className="back">
                    <BsIcons.BsArrowLeftShort /> Kembali ke halaman utama
                </Link>
                <span className="circlexunit" />
                <span className="circleyunit" />

                <Row className="mt-5 px-1">
                    <Col md={12} lg={8} className="d-flex flex-column gap-3">
                        <div>
                            <div style={{ color: "#00923F" }}>
                                <h1 style={{ color: "#364152" }}>MI Plus Asy-Syukriyyah</h1>
                            </div>
                            <p className="text-body-regsuccess">
                                Pendaftaran peserta didik MI Plus Asy-Syukriyyah
                            </p>
                            <hr />
                            <div style={{ color: "#364152" }}>
                                <h4 style={{ marginTop: "64px" }}>
                                    Persyaratan Pendaftaran MI Plus Asy-Syukriyyah
                                </h4>
                                <p className="text-body-unitsuccess">
                                    Apa saja yang akan diperlukan untuk mendaftarkan peserta didik
                                    baru.
                                </p>
                            </div>
                        </div>
                        <div className="infoBox listColored">
                            <h6 style={{ color: "#364152" }}>📝 Persyaratan umum</h6>
                            <ul>
                                <li>
                                    Melakukan pendaftaran secara online di alamat{" "}
                                    <Link
                                        style={{ color: "#00923F" }}
                                        to="https://ppdb.asy-syukriyyah.sch.id/"
                                    >
                                        ppdb.asy-syukriyyah.sch.id
                                    </Link>
                                </li>
                                <li>Mengikuti seleksi observasi dan wawancara orang tua</li>
                                <li>Melengkapi berkas dokumen</li>
                                <li>
                                    Melunasi administrasi keuangan sampai waktu yang ditentukan
                                </li>
                            </ul>
                        </div>
                        <div className="infoBox p-3">
                            <h6 style={{ color: "#364152" }}>👤 Persyaratan Usia</h6>
                            <Row className="justify-content-center gap-2 gap-lg-4 gap-xxl-0 align-items-center unitAge">
                                <Col className="btnlookalike">
                                    <div className="btnlookalike">
                                        <h4>Minimal usia 5 tahun 10 bulan</h4>
                                        <p style={{ color: "#697586" }}>per Juli 2024, atau kelahiran September 2018</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="infoBox listColored">
                            <h6 style={{ color: "#364152" }}>📑 Persyaratan Dokumen</h6>
                            <p>Dokumen dilampirkan pada saat tes masuk/observasi:</p>
                            <ul>
                                <li>
                                Print out Formulir Pendaftaran Online
                                </li>
                                <li>Print out Kartu Tes/Observasi</li>
                                <li>Foto copy akte kelahiran</li>
                                <li>Foto copy KTP orang tua</li>
                                <li>Foto copy kartu keluarga</li>
                                <li>Surat keterangan NISN dari TK</li>
                                <li>Pas foto 3x4 sebanyak 2 lembar</li>
                                <li>Print out MoU yang dapat di download di halaman login</li>
                                <li>Materai tempel 10.000</li>
                            </ul>
                        </div>
                        <div className="infoBox listColored">
                            <h6 style={{ color: "#364152" }}>🗒️ Materi Observasi (Program Reguler)</h6>
                            <ul>
                                <li>Kematangan sosial dan emosi</li>
                                <li>Bahasa</li>
                                <li>Motorik</li>
                                <li>Kognitif</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={"auto"} lg={2} xxl={3} className="mt-3">
                        <Row className="row-cols-1 row-cols-md-2 row-cols-lg-1">
                            <Col>
                                <Card className="cardUnit  mx-auto mx-lg-0 ">
                                    <Card.Img
                                        variant="top"
                                        src="/images/imgmiplus.png"
                                        alt="TKIT"
                                        style={{
                                            height: "220px",
                                            objectFit: "contain",
                                        }}
                                        className="img-fluid"
                                    />
                                    <Card.Body>
                                        <Card.Title>MI Plus Asy-Syukriyyah</Card.Title>
                                        <Card.Text>
                                            <span>Pendaftaran dibuka pada</span>{" "}
                                            <span style={{ color: "#00923F" }}>20 Oktober 2023 </span>
                                        </Card.Text>
                                        {reg !== null ? (
                                            <Link to="/register-reguler">
                                                <Button variant="unit" className="rounded-pill mt-3">
                                                    Daftar Program Reguler
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Button variant="unitClosed" className="rounded-pill mt-3">
                                                Daftar Program Reguler
                                            </Button>
                                        )}
                                        {/* INTERNAL */}
                                        {inter !== null ? (
                                            <Link to="/register-internal">
                                                <Button variant="unit" className="rounded-pill mt-3">
                                                    Daftar Program Internal
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Button variant="unitClosed" className="rounded-pill mt-3">
                                                Daftar Program Internal
                                            </Button>
                                        )}
                                        {/* PINDAHAN */}
                                        {moving !== null ? (
                                            <Link to="/register-pindahan">
                                                <Button variant="unit" className="rounded-pill mt-3">
                                                    Daftar Program Pindahan
                                                </Button>
                                            </Link>
                                        ) : (
                                            <Button variant="unitClosed" className="rounded-pill mt-3">
                                                Daftar Program Pindahan
                                            </Button>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card
                                    style={{ marginTop: "1rem" }}
                                    className="cardUnit  mx-auto mx-0-lg"
                                >
                                    <Card.Body>
                                        <Card.Title
                                            style={{ color: "#00923F", textAlign: "center" }}
                                        >
                                            Kontak MI Plus Asy-Syukriyyah
                                        </Card.Title>
                                        <Card.Text
                                            className="text-center"
                                            style={{ color: "#697586" }}
                                        >
                                            Email: miplus@asy-syukriyyah.sch.id
                                        </Card.Text>
                                        <Button target="_blank" variant="success" className="rounded-pill mt-3" href="https://wa.me/+6281399247740">
                                            WhatsApp
                                        </Button>
                                    </Card.Body>
                                </Card>
                                <Card
                                    style={{ marginTop: "1rem" }}
                                    className="cardUnit  mx-auto mx-0-lg"
                                >
                                    <Card.Body>
                                        <Card.Title
                                            style={{ color: "#00923F", textAlign: "center" }}
                                        >
                                            Brosur MI Plus Asy-Syukriyyah
                                        </Card.Title>
                                        <Card.Text
                                            className="text-center"
                                            style={{ color: "#697586" }}
                                        >
                                            Untuk informasi selanjutnya silahkan download brosur
                                            sekolah di bawah ini.
                                        </Card.Text>
                                        <Button variant="success" className="rounded-pill mt-3" target="_blank" href="https://drive.google.com/file/d/1olqKbu4p5oXHxv-Zp6w99rIh5Z-bchk3/view?usp=share_link">
                                            Download Brosur
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default UnitMIPlus;
