import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Form, Alert } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getDataParent } from "../api_folder/api";
import { sendDataParent } from "../api_folder/api_sendData";

const FormOrtu = () => {

  const localUsrnm = localStorage.getItem('username');
  const regex = /(<([^>]+)>)/gi;

  const [message, setMessage] = useState('');
  const [check, setCheck] = useState(false);

  const [ayah, setAyah] = useState('');
  const [nik0, setNik0] = useState();
  const [year0, setYear0] = useState('');
  const [ed0, setEd0] = useState('');
  const [work0, setWork0] = useState('');
  const [gaji0, setGaji0] = useState('');
  const [phone0, setPhone0] = useState();

  const [ibu, setIbu] = useState('');
  const [nik1, setNik1] = useState();
  const [year1, setYear1] = useState('');
  const [ed1, setEd1] = useState('');
  const [work1, setWork1] = useState();
  const [gaji1, setGaji1] = useState();
  const [phone1, setPhone1] = useState();

  const [wali, setWali] = useState('');
  const [nik2, setNik2] = useState();
  const [year2, setYear2] = useState('');
  const [ed2, setEd2] = useState('');
  const [work2, setWork2] = useState();
  const [gaji2, setGaji2] = useState();
  const [phone2, setPhone2] = useState();

  const handleSubmit = (e) => {
    e.preventDefault()
    sendDataParent(localUsrnm, ayah, nik0, year0, ed0, work0, gaji0, phone0, ibu, nik1, year1, ed1, work1, gaji1, phone1, wali, nik2, year2, ed2, work2, gaji2, phone2).then((res) => {
      setMessage(res.data.message)
    })
  }

  useEffect(() => {
    getDataParent(localUsrnm).then((res) => {
      setAyah(res.data.name_parent_0)
      setNik0(res.data.nik_0)
      setYear0(res.data.birth_year_0)
      setEd0(res.data.education_0)
      setWork0(res.data.work_0)
      setGaji0(res.data.salary_0)
      setPhone0(res.data.phone_number_0)

      setIbu(res.data.name_parent_1)
      setNik1(res.data.nik_1)
      setYear1(res.data.birth_year_1)
      setEd1(res.data.education_1)
      setWork1(res.data.work_1)
      setGaji1(res.data.salary_1)
      setPhone1(res.data.phone_number_1)

      setWali(res.data.name_parent_2)
      setNik2(res.data.nik_2)
      setYear2(res.data.birth_year_2)
      setEd2(res.data.education_2)
      setWork2(res.data.work_2)
      setGaji2(res.data.salary_2)
      setPhone2(res.data.phone_number_2)

    })
  }, [localUsrnm])

  useEffect(() => {
    setMessage('');
  }, [ayah, nik0, year0, ed0, work0, gaji0, phone0, ibu, nik1, year1, ed1, work1, gaji1, phone1, wali, nik2, year2, ed2, work2, gaji2, phone2])

  return (
    <section className="p-5 overflow-hidden">
      <Container className="position-relative">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="d-flex point gap-3 mb-2">
          <h2 className="fw-medium fs-2 ">Edit Data</h2>
        </div>

        <Link to={"/dashboard/profile"} style={{ color: "#00923F" }}>
          <BsArrowLeft /> Kembali ke profile utama
        </Link>
        <Form onSubmit={handleSubmit}>
          <h5 className="fw-medium text-dark mt-3">Data Orang Tua / Wali</h5>
          <Row>
            <Col lg={4}>
              <h5 className="fw-medium text-dark mt-3">Data Ayah</h5>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Nama ayah</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan nama ayah"
                  onChange={e => setAyah(e.target.value)}
                  defaultValue={ayah}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">NIK</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan NIK ayah"
                  onChange={e => setNik0(e.target.value)}
                  defaultValue={nik0}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Tahun Lahir</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan tahun lahir ayah"
                  onChange={e => setYear0(e.target.value)}
                  defaultValue={year0}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Pendidikan terakhir
                </Form.Label>
                <Form.Select
                  required aria-label="Saudara" className="input-comp"
                  onChange={e => setEd0(e.target.value)}
                  value={ed0} placeholder="Pilih pendidikan terakhir ayah">
                  <option value="" >Pilih Pendidikan</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/SMK">SMA/SMK</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Pekerjaan</Form.Label>
                <Form.Select
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan pekerjaan ayah"
                  onChange={e => setWork0(e.target.value)}
                  value={work0}
                  required
                >
                  <option value="" >Pilih Pekerjaan</option>
                  <option value="PNS">PNS</option>
                  <option value="TNI">TNI</option>
                  <option value="POLRI">POLRI</option>
                  <option value="Karyawan Swasta">Karyawan Swasta</option>
                  <option value="Karyawan BUMN">Karyawan BUMN</option>
                  <option value="Wirausaha">Wirausaha</option>
                  <option value="Petani">Petani</option>
                  <option value="Buruh">Buruh</option>
                  <option value="Nelayan">Nelayan</option>
                  <option value="Ibu Rumah Tangga">Ibu Rumah Tangga</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Penghasilan perbulan
                </Form.Label>
                <Form.Select className="input-comp" onChange={e => setGaji0(e.target.value)}
                  value={gaji0}
                  required>
                  <option value="">Pilih rata-rata penghasilan per bulan</option>
                  <option value={1}>Dibawah 5jt</option>
                  <option value={2}>5jt - 10jt</option>
                  <option value={3}>10jt - 20jt</option>
                  <option value={4}>Diatas 20jt</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">No. Telepon</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan no. Telepon"
                  onChange={e => setPhone0(e.target.value)}
                  defaultValue={phone0}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <h5 className="fw-medium text-dark mt-3">Data Ibu</h5>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Nama Ibu</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan nama ibu"
                  onChange={e => setIbu(e.target.value)}
                  defaultValue={ibu}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">NIK</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan NIK ibu"
                  onChange={e => setNik1(e.target.value)}
                  defaultValue={nik1}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Tahun Lahir</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan tahun lahir ibu"
                  onChange={e => setYear1(e.target.value)}
                  defaultValue={year1}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Pendidikan terakhir
                </Form.Label>
                <Form.Select 
                  required aria-label="Saudara" className="input-comp" onChange={e => setEd1(e.target.value)}
                  value={ed1} placeholder="Pilih pendidikan terakhir ibu">
                  <option value="" >Pilih Pendidikan</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/SMK">SMA/SMK</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Pekerjaan</Form.Label>
                <Form.Select
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan pekerjaan ibu"
                  onChange={e => setWork1(e.target.value)}
                  value={work1}
                  required
                >
                  <option value="" >Pilih Pekerjaan</option>
                  <option value="PNS">PNS</option>
                  <option value="TNI">TNI</option>
                  <option value="POLRI">POLRI</option>
                  <option value="Karyawan Swasta">Karyawan Swasta</option>
                  <option value="Karyawan BUMN">Karyawan BUMN</option>
                  <option value="Wirausaha">Wirausaha</option>
                  <option value="Petani">Petani</option>
                  <option value="Buruh">Buruh</option>
                  <option value="Nelayan">Nelayan</option>
                  <option value="Ibu Rumah Tangga">Ibu Rumah Tangga</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Penghasilan perbulan
                </Form.Label>
                <Form.Select className="input-comp" onChange={e => setGaji1(e.target.value)}
                  value={gaji1}
                  required>
                  <option value="">Pilih rata-rata penghasilan per bulan</option>
                  <option value={1}>Dibawah 5jt</option>
                  <option value={2}>5jt - 10jt</option>
                  <option value={3}>10jt - 20jt</option>
                  <option value={4}>Diatas 20jt</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">No. Telepon</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan no. Telepon"
                  onChange={e => setPhone1(e.target.value)}
                  defaultValue={phone1}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={4}>
              <h5 className="fw-medium text-dark mt-3">
                Data wali &#40;Opsional&#41;
              </h5>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Nama wali</Form.Label>
                <Form.Control
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan nama wali"
                  onChange={e => setWali(e.target.value)}
                  defaultValue={wali}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">NIK</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan NIK wali"
                  onChange={e => setNik2(e.target.value)}
                  defaultValue={nik2}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Tahun Lahir</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan tahun lahir wali"
                  onChange={e => setYear2(e.target.value)}
                  defaultValue={year2}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Pendidikan terakhir
                </Form.Label>
                <Form.Select aria-label="Saudara" className="input-comp" onChange={e => setEd2(e.target.value)}
                  value={ed2} placeholder="Pilih pendidikan terakhir wali">
                  <option value="" >Pilih Pendidikan</option>
                  <option value="SMP">SMP</option>
                  <option value="SMA/SMK">SMA/SMK</option>
                  <option value="D1">D1</option>
                  <option value="D2">D2</option>
                  <option value="D3">D3</option>
                  <option value="D4">D4</option>
                  <option value="S1">S1</option>
                  <option value="S2">S2</option>
                  <option value="S3">S3</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">Pekerjaan</Form.Label>
                <Form.Select
                  type="text"
                  className="input-comp"
                  placeholder="Masukkan pekerjaan wali"
                  onChange={e => setWork2(e.target.value)}
                  value={work2}
                >
                  <option value="" >Pilih Pekerjaan</option>
                  <option value="PNS">PNS</option>
                  <option value="TNI">TNI</option>
                  <option value="POLRI">POLRI</option>
                  <option value="Karyawan Swasta">Karyawan Swasta</option>
                  <option value="Karyawan BUMN">Karyawan BUMN</option>
                  <option value="Wirausaha">Wirausaha</option>
                  <option value="Petani">Petani</option>
                  <option value="Buruh">Buruh</option>
                  <option value="Nelayan">Nelayan</option>
                  <option value="Ibu Rumah Tangga">Ibu Rumah Tangga</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-neutral">
                  Penghasilan perbulan
                </Form.Label>
                <Form.Select className="input-comp" onChange={e => setGaji2(e.target.value)}
                  value={gaji2}>
                  <option>Pilih rata-rata penghasilan per bulan</option>
                  <option value={1}>Dibawah 5jt</option>
                  <option value={2}>5jt - 10jt</option>
                  <option value={3}>10jt - 20jt</option>
                  <option value={4}>Diatas 20jt</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="textComp">
                <Form.Label className="text-neutral">No. Telepon</Form.Label>
                <Form.Control
                  type="number"
                  className="input-comp"
                  placeholder="Masukkan no. Telepon"
                  onChange={e => setPhone2(e.target.value)}
                  defaultValue={phone2}
                />
              </Form.Group>
              <hr />
              <Form.Check
                onChange={e => setCheck(e.target.checked)}
                type="checkbox"
                id="checkbox"
                label="Dengan ini saya menyatakan bahwa data yang saya isi adalah benar adanya. Jika dikemudian hari terbukti bahwa data tersebut tidak benar, maka saya bersedia didisklualifikasi dari proses PPDB Asy-Syukriyyah."
                style={{ color: "#697586" }}
              />
              <Row className="mt-2 gap-3 gap-md-0">
                {message && (
                  <>
                    {message !== 'input form berhasil' ? (
                      <Alert variant="danger">
                        {message.replace(regex, " ")}
                      </Alert>
                    ) : (
                      <Alert variant="success">
                        {message.replace(regex, " ")}
                      </Alert>
                    )}
                  </>
                )}
                <Col sm={6}>
                  <Button
                    disabled={!check}
                    variant="save-edit"
                    style={{ color: "#00923F", background: "#FCFCFD" }}
                    type="submit"
                  >
                    Simpan
                  </Button>
                </Col>
                <Col sm={6}>
                  <Link to={"/dashboard/profile"} style={{ color: "#00923F" }}>
                    <Button
                      variant="save-edit"
                      style={{ background: "#00923F", color: "#FCFCFD" }}
                    >
                      Selesai
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  );
};

export default FormOrtu;
