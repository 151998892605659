import { useState } from "react";
import "../styles/dashboardPayment.css";

// Faq source
import { faqSource } from "../data";

// Bootstrap
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";

const DashboardPayment = () => {
  const [activeId, setActiveId] = useState(1);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  return (
    <div className="p-5 mb-5 overflow-hidden min-vh-100" style={{ zIndex: '-9999' }}>
      <Container fluid className="position-relative">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="d-flex point gap-3 mb-2">
          <h2 className="fw-medium">Informasi pembayaran</h2>
        </div>
        <Accordion className="bg-transparent mt-5">
          {faqSource.faq.map((question, index) => {
            return (
              <div className="rounded-lg mb-3 border-0" key={index}>
                <Accordion.Item
                  eventKey={index + 1}
                  onClick={() => toggleActive(index + 1)}
                  className={`${activeId === index + 1 ? "active" : null
                    } border-0`}
                >
                  <Accordion.Header>{question.question}</Accordion.Header>
                </Accordion.Item>
                <Accordion.Collapse eventKey={index + 1} className="mt-1">
                  <div>{question.answer}</div>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      </Container>
    </div>
  );
};
export default DashboardPayment;
