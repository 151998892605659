import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { cekBiaya } from "../api_folder/api";

const Biaya = () => {
    const localUsrnm = localStorage.getItem('username');
    const [data, setData] = useState([]);

    useEffect(() => {
        cekBiaya(localUsrnm).then((res) => {
            setData(res.data);
        })
    },[localUsrnm])

    let budget = data.budget;
    return (
        <>
            <div 
            style={{backgroundColor:'#F8FAFC', boxShadow:'0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)', borderRadius:'8px'}}
            className="p-3"
            >
                <h6>Biaya pendidikan unit {data.name_unit}</h6>
                <p>Biaya pendidikan untuk pendaftaran unit {data.name_unit} adalah senilai:</p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="price" className="rounded-pill mt-3">Rp {new Intl.NumberFormat('ID', { currency: 'IDR' }).format(budget)}</Button>{' '}
                </div>
            </div>
        </>
    )
}
export default Biaya;