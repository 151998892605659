import { useState } from "react";
import "../styles/dashboardPayment.css";

import { accordion } from "../data";

// Bootstrap
import Accordion from "react-bootstrap/Accordion";
import { Container } from "react-bootstrap";

const DashboardDownload = () => {
  const [activeId, setActiveId] = useState(1);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  return (
    <div className="p-5 mb-5 overflow-hidden min-vh-100">
      <Container fluid className="position-relative">
        <div className="gradient-top-right"></div>
        <div className="gradient-right"></div>
        <div className="header-download1">
          <div className="header-download"></div>
          <h2 className="h4-download">Download Dokumen</h2>
        </div>
        <Accordion className="bg-transparent mt-5">
          {accordion.accrdn.map((x, index) => {
            return (
              <div className="rounded-lg mb-3 border-0" key={index}>
                <Accordion.Item
                  eventKey={index + 1}
                  onClick={() => toggleActive(index + 1)}
                  className={`${activeId === index + 1 ? "active" : null
                    } border-0`}
                >
                  <Accordion.Header>{x.title}</Accordion.Header>
                </Accordion.Item>
                <Accordion.Collapse eventKey={index + 1} className="mt-1">
                  <div>{x.content}</div>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      </Container>
    </div>
  );
};
export default DashboardDownload;
